import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { SplashComponent } from './splash-ui/splash/splash.component';
import { SignupCompanyMemberComponent } from './splash-ui/signup-company-member/signup-company-member.component';

const routes: Routes = [
  { path: '', redirectTo: '/pages/splash', pathMatch: 'full' },
  { path: 'pages/main', component: MainComponent},
  { path: 'pages/splash', component: SplashComponent},
  { path: 'pages/signupcompanymember', component: SignupCompanyMemberComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
