import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { VideoInfo } from "../../my-type";

@Component({
  selector: 'app-show-content',
  templateUrl: './show-content.component.html',
  styleUrls: ['./show-content.component.css']
})
export class ShowContentComponent implements OnInit {

  currentVideo: VideoInfo = null;
  displayDialog: boolean = false;
  playerComplete: boolean = false;
  viewingStatus: String = "ready";
  
  @Input() assetDir: String;

  @ViewChild("playerEle", { static: false }) playerEle: ElementRef
  @ViewChild("videoSrcEle", { static: false }) videoSrcEle: ElementRef

  show(video: VideoInfo)
  {
    console.log(`video:${JSON.stringify(video)}`);
    this.currentVideo = video;
    this.playerComplete = false;
    this.displayDialog = true;
    this.viewingStatus = 'seeing';

    setTimeout(() => {
      this.playerEle.nativeElement.setAttribute("header", video.title);
      this.videoSrcEle.nativeElement.setAttribute("src", this.assetDir + "/videos/" + video.file);
      this.playerEle.nativeElement.load();
      this.playerEle.nativeElement.play();
    }, 33);
  }

  onPlayerHide()
  {
    console.log("player hide");
    this.playerEle.nativeElement.pause();
  }

  onVideoEnded()
  {
    this.playerComplete = true;
    console.log(`ended:${this.currentVideo.title}`);
  }

  showAssessment()
  {
    this.viewingStatus='assessment';
  }

  applyAssessment()
  {
    this.displayDialog = false;
  }

  constructor() { }

  ngOnInit() {
  }

}
