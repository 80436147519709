import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CompanyInfo, CharityInfo } from '../../my-type';
import { FileUpload } from 'primeng/fileupload';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../data.service';

// tslint:disable: variable-name

@Component({
  selector: 'app-charity-upload-content',
  templateUrl: './charity-upload-content.component.html',
  styleUrls: ['./charity-upload-content.component.css']
})
export class CharityUploadContentComponent implements OnInit {

  isEditMode = false;
  displayDialog = false;

  selectedThumbnailFiles: any = null;
  selectedVideoFiles: any = null;

  charity_id = 0;
  name = '';
  description = '';
  thumbnailFile: string = null;
  videoFile: string = null;

  @Input() onDoneUpload: Function;
  @ViewChild('charityNameField', { static: true }) charityNameField: ElementRef;

  constructor(private httpClient: HttpClient, private ds: DataService) { }

  open(data: CharityInfo) {
    const me = this;

    this.selectedThumbnailFiles = null;
    this.selectedVideoFiles = null;

    if (!data) {
      this.charity_id = 0;
      this.name = '';
      this.description = '';
      this.thumbnailFile = null;
      this.videoFile = null;
      this.isEditMode = false;
    } else {
      this.charity_id = data.id;
      this.name = data.name;
      this.description = data.description;
      this.thumbnailFile = data.thumbnail;
      this.videoFile = data.video;
      this.isEditMode = true;
    }

    this.displayDialog = true;
    setTimeout(() => {
      me.charityNameField.nativeElement.focus();
    }, 100);
  }

  apply() {
    if (!this.selectedThumbnailFiles && !this.selectedVideoFiles) {
      this.onUpload();
    } else {
      if (this.selectedThumbnailFiles) { this.thumbnailFile = null; }
      if (this.selectedVideoFiles) { this.videoFile = null; }

      if (this.selectedVideoFiles) { this.onVideoFileUpload(this.selectedVideoFiles); }
      if (this.selectedThumbnailFiles) { this.onThumbnailFileUpload(this.selectedThumbnailFiles); }
    }
  }

  delete() {
    const me = this;
    this.ds.call('charity.delete',
      {
        id: this.charity_id
      },
      (c, d) => {
        if (c === 200) {
          console.log('d=', d);
          me.displayDialog = false;
          me.onDoneUpload();
        }
      });
  }

  onDialogHide() {
    console.log('upload content dialog closed!');
  }

  onVideoFileUpload(file) {
    const me = this;
    console.log('file:', file);

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    this.httpClient
      .post<any>(this.ds.getFileServerUrl() + '/upload', formData)
      .subscribe(r => {
        console.log('videoFile:', r);
        me.videoFile = r.file;
        me.onUpload();
      });
  }

  onThumbnailFileUpload(file) {
    const me = this;
    console.log('file:', file);

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    this.httpClient
      .post<any>(this.ds.getFileServerUrl() + '/upload', formData)
      .subscribe(r => {
        console.log('thumbnailFile:', r);
        me.thumbnailFile = r.file;
        me.onUpload();
      });
  }

  onUpload() {
    const me = this;
    if (this.thumbnailFile != null && this.videoFile != null) {
      const payload: CharityInfo = {
        id: this.charity_id,
        name: this.name,
        description: this.description,
        thumbnail: this.thumbnailFile,
        video: this.videoFile
      };

      this.ds.call((this.isEditMode) ? 'charity.set' : 'charity.add', payload, (c, d: CharityInfo[]) => {
        if (c === 200 && d[0].name === this.name) {
          console.log(d);
          me.displayDialog = false;
          me.onDoneUpload();
        } else {
          // TODO: 에러 메시지 표시 필요
        }
      });
    }
  }

  ngOnInit() {
  }

}
