import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { LightboxModule } from 'primeng/lightbox';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ChartModule } from 'primeng/chart';
import { PanelMenuModule } from 'primeng/panelmenu';
import { StepsModule } from 'primeng/steps';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { GalleriaModule } from 'primeng/galleria';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { SliderModule } from 'primeng/slider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginatorModule } from 'primeng/paginator';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
// import { ContentsComponent } from './contents/contents.component';
import { CompanyComponent } from './company-ui/company/company.component';
import { CharityComponent } from './charity-ui/charity/charity.component';
// import { LoginComponent } from './login/login.component';
// import { SignupComponent } from './signup/signup.component';
import { UploadContentComponent } from './video-ui/upload-content/upload-content.component';
import { ShowContentComponent } from './video-ui/show-content/show-content.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SplashComponent } from './splash-ui/splash/splash.component';
import { SignupCompanyMemberComponent } from './splash-ui/signup-company-member/signup-company-member.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { MoviesComponent } from './video-ui/movies/movies.component';
import { CharityUploadContentComponent } from './charity-ui/charity-upload-content/charity-upload-content.component';
import { CharityDetailComponent } from './charity-ui/charity-detail/charity-detail.component';
import { MyInfoComponent } from './my-info/my-info.component';
import { MyFileUploadComponent } from './components/my-file-upload/my-file-upload.component';
import { CompanyMemberViewComponent } from './company-ui/company-member-view/company-member-view.component';
import { CompanyDetailComponent } from './components/company-detail/company-detail.component';
import { UploadVideoComponent } from './video-ui/upload-video/upload-video.component';
import { ChartComponent } from './components/chart/chart.component';
import { G2ChartComponent } from './components/g2-chart/g2-chart.component';
import { UploadCharityVideoComponent } from './charity-ui/upload-charity-video/upload-charity-video.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    // ContentsComponent,
    CompanyComponent,
    CharityComponent,
    // LoginComponent,
    // SignupComponent,
    UploadContentComponent,
    ShowContentComponent,
    AnalyticsComponent,
    SplashComponent,
    SignupCompanyMemberComponent,
    DashBoardComponent,
    UserInfoComponent,
    MoviesComponent,
    CharityUploadContentComponent,
    CharityDetailComponent,
    MyInfoComponent,
    MyFileUploadComponent,
    CompanyMemberViewComponent,
    CompanyDetailComponent,
    UploadVideoComponent,
    ChartComponent,
    G2ChartComponent,
    UploadCharityVideoComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MenubarModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    LightboxModule,
    DialogModule,
    RatingModule,
    ProgressBarModule,
    InputTextareaModule,
    SidebarModule,
    ToastModule,
    DropdownModule,
    FileUploadModule,
    HttpClientModule,
    ChartModule,
    PanelMenuModule,
    StepsModule,
    ConfirmDialogModule,
    GalleriaModule,
    PanelModule,
    TabMenuModule,
    SliderModule,
    ScrollPanelModule,
    CalendarModule,
    ChipsModule,
    TableModule,
    RadioButtonModule,
    CheckboxModule,
    PaginatorModule,
    ProgressSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
