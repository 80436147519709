import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { CharityInfo, VideoCardInfo, VideoInfo } from 'src/app/my-type';

@Component({
  selector: 'app-charity-detail',
  templateUrl: './charity-detail.component.html',
  styleUrls: ['./charity-detail.component.css']
})
export class CharityDetailComponent implements OnInit {

  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @Input() CharityId: number;

  charity: CharityInfo;
  videos: VideoCardInfo[] = [];
  assetDir = '/assets/';
  totalSeen = 0;
  totalDonation = 0;

  constructor(private ds: DataService) { }

  async update() {
    if (this.CharityId && this.CharityId !== 0) {
      this.charity = (await this.ds.get('charity.get', {id: this.CharityId}) as CharityInfo[])[0];
      this.videos = await this.ds.get('video.listByCharityId', {charity_id: this.CharityId}) as VideoCardInfo[];
      this.totalSeen = 0;
      this.totalDonation = 0;
      this.videos.forEach(x => {
        x.total_donation = x.donations.reduce( (t, i) => t + i.total_donation, 0);
        x.progress_percent = (x.seen * x.unit_price * 100) / x.total_donation;
        this.totalSeen += x.seen;
        this.totalDonation += x.seen * x.unit_price;
      });
    }
  }

  ngOnInit() {
    this.assetDir = this.ds.getAssetDir();
    this.update();
  }

}
