import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
// import { } from '../contents/contents.component';
import { DataService } from '../data.service';
// import { SignupComponent } from '../signup/signup.component';
// import { LoginComponent } from '../login/login.component';
// import { UserInfoComponent } from '../user-info/user-info.component';
// import { MoviesComponent } from '../movies/movies.component';
// import { DashBoardComponent } from '../dash-board/dash-board.component';
import { UploadCharityVideoComponent } from '../charity-ui/upload-charity-video/upload-charity-video.component';

import { Router } from '@angular/router';
import { SessionInfo, CompanyInfo, Summary } from '../my-type';

declare const gapi: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {

  constructor(private ds: DataService, private router: Router) { }

  items: MenuItem[];
  selected = 'dashboard';
  dataForSelectedMenu = null;
  userInfo: SessionInfo = null;
  companyInfo: CompanyInfo = null;
  summary: Summary = null;

  // @ViewChild(SignupComponent) signupCmp: SignupComponent;
  // @ViewChild(LoginComponent) loginCmp: LoginComponent;

  // doLogedIn()
  // {
  //   console.log("doLogedIn");

  //   let me = this;
  //   this.loginSidebar = false;
  //   this.ds.call("user.info", {}, function(c, d) {
  //     if (c==200)
  //     {
  //       me.userInfo = d;
  //       console.log(`userInfo: ${me.userInfo}`)
  //     }
  //   });
  // }

  onSelect(data) {

  }

  doLogOut() {
    const me = this;
    if (gapi.auth2) {
      const auth = gapi.auth2.getAuthInstance();
      if (auth) {
        auth.signOut().then(() => {
          console.log('User signed out.');
          me.router.navigateByUrl('/pages/splash');
        });
      } else {
        me.router.navigateByUrl('/pages/splash');
      }
    } else {
      me.router.navigateByUrl('/pages/splash');
    }
  }

  async updateData() {
    this.userInfo = await this.ds.get('user.info', {}) as SessionInfo;
    this.companyInfo = await this.ds.get('company.get', {id: this.userInfo.company_id}) as CompanyInfo;
    this.summary = (await this.ds.get('summary', {}) as Summary[])[0];
    console.log('summary: ', this.summary);
  }

  chooseMenu(e: any) {
    this.selected = e.menu;
    this.dataForSelectedMenu = e.data;
  }

  ngOnInit() {
    this.items = [
      {
        label: '대시보드',
        icon: 'fas fa-chalkboard',
        command: (e) => { this.selected = 'dashboard'; }
      },
      // {
      //   label: '광고주 관리',
      //   icon: 'fas fa-film',
      //   command: (e) => { this.selected = 'company'; }
      // },
      {
        label: '광고 관리',
        icon: 'fas fa-film',
        command: (e) => { this.selected = 'movie'; }
      },
      {
        label: '기부 단체',
        icon: 'fa fa-ribbon',
        command: (e) => { this.selected = 'charity'; }
      },
      {
        label: '내 정보',
        icon: 'fas fa-user-cog',
        command: (e) => { this.selected = 'userinfo'; }
      },
      { separator: true },
      {
        label: '분석',
        icon: 'fa fa-chart-line',
        command: (e) => { this.selected = 'analytics'; }
      }
    ];

    this.updateData();
  }

}
