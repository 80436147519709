import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

declare const gapi: any;
declare var FB: any;

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css'],
  providers: [ConfirmationService]
})

export class SplashComponent implements OnInit, AfterContentChecked {

  ngAfterContentChecked() {
    console.log('ngAfterContentChecked');
  }

  doRoute(code) {
    const me = this;

    switch (code) {
      case 'Unauthorized':
        this.confirmationService.confirm({
          message: 'Are you sure that you want to perform this action?',
          accept: () => {
              // Actual logic to perform a confirmation
          }
        });

        break;
      case 'NeedToFill':
        me.router.navigateByUrl('/pages/signupcompanymember');
        break;
      case 'Checking':
        console.log('checking');
        me.confirmationService.confirm({
          message: '아직 심사가 진행중 입니다.\n 일반적으로 46시간 안에 완료되며 등록하신 연락처로 결과가 전달됩니다.\n 감사합니다.',
          header: '심사 진행 중',
          icon: 'fas fa-heartbeat',
          accept: () => {
            console.log('accept');
            me.router.navigateByUrl('/pages/signupcompanymember');
          },
          rejectVisible: false
        });
        break;
      case 'Valid':
        me.router.navigateByUrl('/pages/main');
        break;
    }
  }

  // onSignIn(googleUser) {
  //   const me = this;
  //   const profile = googleUser.getBasicProfile();

  //   console.log(`id: ${profile.getId()}`);
  //   console.log(`name: ${profile.getName()}`);
  //   console.log(`email: ${profile.getEmail()}`);
  //   console.log(`imageUrl: ${profile.getImageUrl()}`);
  //   console.log(`givenName: ${profile.getGivenName()}`);
  //   console.log(`familyName: ${profile.getFamilyName()}`);

  //   const response = googleUser.getAuthResponse();
  //   console.log(`response: ${response.id_token}`);
  //   this.ds.call('user.login.google', {
  //     token: response.id_token
  //   }, async (c, d) => {
  //     if (c === 200) {
  //       await me.ds.get('user.info', {});
  //       me.doRoute(d.status);
  //     }
  //     console.log(`response: ${d}`);
  //   });
  // }

  doGoogleLogin() {
    const me = this;
    const gauth = gapi.auth2.getAuthInstance();
    console.log('gauth: ', gauth);
    gauth.signIn().then( (googleUser) => {
      console.log('googleUser: ', googleUser);
      const profile = googleUser.getBasicProfile();
      console.log('id: ', profile.getId());
      console.log('name: ', profile.getName());
      console.log('image: ', profile.getImageUrl());
      console.log('email: ', profile.getEmail());
      const response = googleUser.getAuthResponse();
      console.log(`response: ${response.id_token}`);
      this.ds.call('user.login.google', {
        token: response.id_token
      }, async (c, d) => {
        if (c === 200) {
          await me.ds.get('user.info', {});
          me.doRoute(d.status);
        }
        console.log('response: ', c, d);
      });
    });

    // gapi.signin2.authorize({
    //   client_id: '155729315932-qful4j4i6d20rb2hsia994e6c5b9gc9j.apps.googleusercontent.com',
    //   scope: 'email profile',
    //   response_type: 'id_token permission'
    // }, function(response) {
    //   if (response.error) {
    //     // An error happened!
    //     console.log(response.error);
    //     return;
    //   }
    //   // The user authorized the application for the scopes requested.
    //   var accessToken = response.access_token;
    //   var idToken = response.id_token;
    //   console.log(`accessToken:${accessToken}, idToken:${idToken}`);
    //   // You can also now use gapi.client to perform authenticated requests.
    // });
  }

  doFacebookLogin() {
    const me = this;
    console.log('submit login to facebook');
    // FB.login();
    FB.login((response) => {
          console.log('submitLogin', response);
          if (response.authResponse) {
            console.log('OK!');
            console.log(`token:${response.authResponse.accessToken}`);

            this.ds.call('user.login.facebook', {
              token: response.authResponse.accessToken
            }, (c, d) => {
              if (c === 200) {
                me.router.navigateByUrl('/pages/main');
              }
              console.log(`response: ${d}`);
            });
           } else {
           console.log('User login failed');
           }
      });
  }

  constructor(private ds: DataService, private router: Router, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    console.log('ngOnInit');

    (window as any).fbAsyncInit = () => {
      FB.init({
        appId      : '2673046492724161',
        cookie     : true,
        xfbml      : true,
        version    : 'v3.3'
      });

      FB.AppEvents.logPageView();

    };

    (function(d, s, id) {
       let js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return; }
       js = d.createElement(s); js.id = id;
       js.src = 'https://connect.facebook.net/en_US/sdk.js';
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));

    setTimeout(() => {
      gapi.load('auth2', () => {
        const auth = gapi.auth2.init({
          client_id: '155729315932-qful4j4i6d20rb2hsia994e6c5b9gc9j.apps.googleusercontent.com',
          scope: 'email profile'
        });
        auth.then(() => {
          console.log('init ok');
        }, () => {
          console.log('init fail!');
        });
      });
    }, 100);
  }

}
