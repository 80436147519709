import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { CharityInfo, VideoInfo } from '../../my-type';
import { DataService } from '../../data.service';
import {MessageService, ConfirmationService} from 'primeng/api';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UploadVideoComponent implements OnInit {

  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('ThumbnailPreview', { static: false }) ThumbnailPreview: ElementRef;
  @ViewChild('VideoPreview', { static: false }) VideoPreview: ElementRef;
  @ViewChild('spinnerEle', { static: false }) spinnerEle: ElementRef;
  @Input() videoId: number;

  assetDir = '/assets/';

  tabNo = 1;
  unitPrice = 1000;
  urlForThumbnailPreview: string = null;
  urlForVideoPreview: string = null;
  selectedThumbnailFiles: File = null;
  selectedVideoFiles: File = null;
  charities: CharityInfo[] = [];
  isEditMode = false;
  total_donation = 0;
  charitiesAndAmounts: any[] = [];
  title = '';
  description = '';
  thumbnailFile: string = null;
  videoFile: string = null;
  researchQuestions: string[] = [];
  researchQuestionTypes: any[] = [];
  start_date: Date = null;
  end_date: Date = null;
  donator_site = '';
  tag: string[] = [];

  target_ages = [
    {display: '전체', value: 'all'},
    {display: '20대', value: '20'},
    {display: '30대', value: '30'},
    {display: '40대', value: '40'},
    {display: '50대', value: '50'},
    {display: '그외', value: 'etc'}
  ];

  target_regions = [
    {display: '전체', value: 'all'},
    {display: '서울', value: 'seoul'},
    {display: '부산', value: 'busan'},
    {display: '경기', value: 'gyeonggi'},
    {display: '그외', value: 'etc'}
  ];

  questionTypeOptions = [
    {label: '5점척도', value: '5score'},
    {label: '가부', value: 'yesno'}
  ];

  target_gender = 0;
  target_age: boolean[] = [];
  target_region: boolean[] = [];

  constructor(private httpClient: HttpClient, private ds: DataService,
              private messageService: MessageService, private confirmationService: ConfirmationService) { }

  onThumbnailSelected(e) {
    const file = e.target.files[0];
    const me = this;

    if (this.urlForThumbnailPreview) { URL.revokeObjectURL(this.urlForThumbnailPreview); }

    if (file) {
      this.selectedThumbnailFiles = file;
      this.urlForThumbnailPreview = URL.createObjectURL(file);
      setTimeout(() => {
        me.ThumbnailPreview.nativeElement.src = me.urlForThumbnailPreview;
      }, 100);
      console.log('file: ', file.name);
      // this.fileDropName.nativeElement.innerText = file.name;
    } else {
      this.urlForThumbnailPreview = null;
      setTimeout(() => {
        me.ThumbnailPreview.nativeElement.removeAttribute('src');
      }, 100);
      // this.fileDropName.nativeElement.innerText = 'no file';
    }

  }

  onVideoSelected(e) {
    const file = e.target.files[0];
    const me = this;

    if (this.urlForVideoPreview) { URL.revokeObjectURL(this.urlForVideoPreview); }

    if (file) {
      this.selectedVideoFiles = file;
      this.urlForVideoPreview = URL.createObjectURL(file);
      setTimeout(() => {
        this.VideoPreview.nativeElement.src = this.urlForVideoPreview;
      }, 100);
      console.log('file: ', file.name);
      // this.fileDropName.nativeElement.innerText = file.name;
    } else {
      this.urlForVideoPreview = null;
      setTimeout(() => {
        this.VideoPreview.nativeElement.removeAttribute('src');
      }, 100);
      // this.fileDropName.nativeElement.innerText = 'no file';
    }
  }

  removeVideo() {
    this.urlForVideoPreview = null;
    this.selectedVideoFiles = null;
    this.videoFile = null;
  }

  removeThumbnail() {
    this.urlForThumbnailPreview = null;
    this.selectedThumbnailFiles = null;
    this.thumbnailFile = null;
  }

  open(data: VideoInfo) {
    const me = this;

    if (data) {
      this.isEditMode = true;
      this.videoId = data.id;
      this.title = data.title;
      this.description = data.description;
      this.thumbnailFile = data.thumbnail;
      this.videoFile = data.file;

      if (this.thumbnailFile) {
        this.urlForThumbnailPreview = me.assetDir + '/images/' + me.thumbnailFile;
        setTimeout(() => {
          me.ThumbnailPreview.nativeElement.src = me.urlForThumbnailPreview;
        }, 100);
      }

      if (this.videoFile) {
        this.urlForVideoPreview = me.assetDir + '/videos/' + me.videoFile;
        setTimeout(() => {
          me.VideoPreview.nativeElement.src = me.urlForVideoPreview;
        }, 100);
      }

      this.researchQuestions = [];
      this.researchQuestionTypes = [];

      for (const item of data.survey) {
        if (typeof item === 'string') {
          this.researchQuestions.push(item);
          this.researchQuestionTypes.push(this.questionTypeOptions.find(option => option.value === '5score'));
        } else {
          this.researchQuestions.push(item.question);
          this.researchQuestionTypes.push(this.questionTypeOptions.find(option => option.value === item.type));
        }
      }

      console.log('researchQuestionTypes: ', this.researchQuestionTypes);

      this.unitPrice = data.unit_price;
      this.start_date = new Date(data.start_date);
      this.end_date = new Date(data.end_date);
      this.donator_site = data.donator_site;
      this.tag = data.tag;
      this.charitiesAndAmounts = data.donations.map(
        item => ({charity: this.charities.find(item2 => item2.id === item.charity_id), total_donation: item.total_donation}));
      this.target_gender = data.target_gender;
      this.target_age = this.target_ages.map(item => data.target_age.findIndex(item2 => item.value === item2) !== -1);
      this.target_region = this.target_regions.map(item => data.target_region.findIndex(item2 => item.value === item2) !== -1);
    } else {
      this.isEditMode = false;

      this.videoId = 0;
      this.total_donation = 0;
      this.charitiesAndAmounts = [
        {
          charity: null,
          total_donation: 0
        }
      ];
      this.title = '';
      this.description = '';
      this.thumbnailFile = null;
      this.videoFile = null;
      this.unitPrice = 1000;
      this.researchQuestions = [''];
      this.start_date = null;
      this.end_date = null;
      this.donator_site = '';
      this.tag = [];
    }
  }

  addResearchQuestions() {
    if (this.researchQuestions.length < 7) {
      this.researchQuestions.push('');
    } else {
      this.messageService.add({key: 'tc', severity: 'info', summary: '설문 최대', detail: '설문은 최대 7개까지 등록가능합니다.'});
    }
  }

  removeResearchQuestions(idx) {
    if (this.researchQuestions.length > 1) {
      this.researchQuestions.splice(idx, 1);
    } else {
      this.messageService.add({key: 'tc', severity: 'info', summary: '설문 최소', detail: '설문은 최소 1개를 입력하셔야 합니다.'});
    }
  }

  addCharity() {
    if (this.charitiesAndAmounts.length < 6) {
      this.charitiesAndAmounts.push(
        {
          charity: null,
          total_donation: 0
        }
      );
    } else {
      this.messageService.add({key: 'tc', severity: 'info', summary: '기부 단체 최대', detail: '기부 단체는 최대 6개를 선택하실 수 있습니다.'});
    }
  }

  removeCharity(idx) {
    if (this.charitiesAndAmounts.length > 1) {
      this.charitiesAndAmounts.splice(idx, 1);
    } else {
      this.messageService.add({key: 'tc', severity: 'info', summary: '기부 단체 최소', detail: '기부 단체는 최소 1개를 선택하셔야 합니다.'});
    }
  }

  changeTab(no: number) {
    this.tabNo = no;
  }

  onUpload() {
    const me = this;
    if (this.thumbnailFile != null && this.videoFile != null) {
      const survey: any[] = [];

      for (let idx = 0; idx < this.researchQuestions.length; idx++) {
        const q = this.researchQuestions[idx];
        const qt = this.researchQuestionTypes[idx];
        survey.push({
          type: qt.value,
          question: q
        });
      }

      const payload: VideoInfo = {
        id: this.videoId,
        title: this.title,
        description: this.description,
        thumbnail: this.thumbnailFile,
        file: this.videoFile,
        survey: survey,
        donations: this.charitiesAndAmounts.map( item =>
          ({charity_id: item.charity.id, total_donation: parseInt(item.total_donation, 10)})),
        target_gender: this.target_gender,
        target_age: this.target_age.map( (item, idx) => (item) ? this.target_ages[idx].value : null ),
        target_region: this.target_region.map( (item, idx) => (item) ? this.target_regions[idx].value : null ),
        unit_price: this.unitPrice,
        start_date: this.start_date.getTime(),
        end_date: this.end_date.getTime(),
        donator_site: this.donator_site,
        tag: this.tag
      };

      if (this.isEditMode) {
        this.ds.call('video.set', payload, (c, d: any) => {
          console.log(d);
          me.spinnerEle.nativeElement.style.display = 'none';
          if (c === 200) {
            me.itemSelected.emit({menu: 'movie'});
          } else {
            // TODO: 에러 메시지 표시 필요
          }
        });
      } else {
        this.ds.call('video.add', payload, (c, d: VideoInfo[]) => {
          console.log(d);
          me.spinnerEle.nativeElement.style.display = 'none';
          if (c === 200) {
            me.itemSelected.emit({menu: 'movie'});
          } else {
            // TODO: 에러 메시지 표시 필요
          }
        });
      }
    }
  }

  doVideoFileUpload(file) {
    const me = this;
    console.log('file:', file);

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    this.httpClient
        .post<any>(this.ds.getFileServerUrl() + '/upload', formData)
        .subscribe(r => {
          console.log('videoFile:', r);
          me.videoFile = r.file;
          me.onUpload();
        });
  }

  doThumbnailFileUpload(file) {
    const me = this;
    console.log('file:', file);

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    this.httpClient
        .post<any>(this.ds.getFileServerUrl() + '/upload', formData)
        .subscribe(r => {
          console.log('thumbnailFile:', r);
          me.thumbnailFile = r.file;
          me.onUpload();
        });
  }

  apply() {

    if (this.charitiesAndAmounts.length <= 0) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '기부단체', detail: '1개 이상의 단체가 선택되어야 합니다.'});
      return;
    }

    for (const x of this.charitiesAndAmounts) {
      if (x.charity == null || x.charity.id === 0) {
        this.messageService.add({key: 'tc', severity: 'error', summary: '기부단체', detail: '단체가 선택되지 않은 항목이 있습니다.'});
        return;
      }
      if (x.total_donation === 0) {
        this.messageService.add({key: 'tc', severity: 'error', summary: '기부단체', detail: '기부 단체중에 기부금이 0원인 항목이 있습니다.'});
        return;
      }
    }

    if (!this.urlForThumbnailPreview && !this.thumbnailFile) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '썸네일', detail: '썸네일을 선택해주세요.'});
      return;
    }

    if (!this.urlForVideoPreview && !this.videoFile) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '동영상', detail: '동영상을 선택해주세요.'});
      return;
    }

    if (this.title == null || this.title.trim().length === 0) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '제목', detail: '제목을 입력해주세요.'});
      return;
    }

    if (this.description == null || this.description.trim().length === 0) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '내용', detail: '내용을 입력해주세요.'});
      return;
    }

    if (this.start_date == null || this.start_date.getFullYear() <= 2018) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '기간', detail: '시작일을 입력해주세요.'});
      return;
    }

    if (this.end_date == null || this.end_date.getFullYear() <= 2018) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '기간', detail: '종료일을 입력해주세요.'});
      return;
    }

    if (this.start_date.getTime() >= this.end_date.getTime() ) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '기간', detail: '종료일은 시작일 이후로 설정하세요.'});
      return;
    }

    if (this.researchQuestions.length <= 0) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '설문', detail: '1개 이상의 설문을 등록하셔야 합니다.'});
      return;
    }

    for (const it of this.researchQuestions) {
      if (it.trim().length <= 0) {
        this.messageService.add({key: 'tc', severity: 'error', summary: '설문', detail: '등록된 설문 항목 중에 내용이 없는 설문이 있습니다.'});
        return;
      }
    }

    if (this.target_age.length === 0 || !this.target_age.find(x => x != null)) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '연령대', detail: '선택된 노출 연령대가 없습니다.'});
      return;
    }

    if (this.target_region.length === 0 || !this.target_region.find(x => x != null)) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '지역', detail: '선택된 노출 지역이 없습니다.'});
      return;
    }

    this.spinnerEle.nativeElement.style.display = 'block';

    if (!this.selectedVideoFiles && !this.selectedThumbnailFiles) {
      this.onUpload();
    } else {
      if (this.selectedThumbnailFiles) { this.thumbnailFile = null; }
      if (this.selectedVideoFiles) { this.videoFile = null; }
    }

    if (this.selectedVideoFiles) { this.doVideoFileUpload(this.selectedVideoFiles); }
    if (this.selectedThumbnailFiles) { this.doThumbnailFileUpload(this.selectedThumbnailFiles); }

  }

  async update() {
    const me = this;
    this.charities = await this.ds.get('charity.list', {}) as CharityInfo[];
    if (this.videoId && this.videoId !== 0) {
      this.ds.call('video.get', {id : this.videoId}, (c, d: VideoInfo) => {
        if (c === 200) {
          console.log(d);
          me.open(d[0]);
        }
      });
    } else {
      me.open(null);
    }
  }

  numArray(max: number) {
    const arr = [];
    for ( let i = 0; i < max; i++) {
      arr.push(i);
    }
    return arr;
  }

  delete() {
    const me = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to erase this item?',
      accept: () => {
        this.ds.call('video.delete',
        {
          id: this.videoId
        },
        (c, d) => {
          if (c === 200) {
            me.itemSelected.emit({menu: 'movie'});
          }
        });
      }
    });
  }

  ngOnInit() {
    this.assetDir = this.ds.getAssetDir();
    this.update();
  }

}
