import { Injectable, Inject } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  ws = null;
  needToReconnect = true;
  seq = 1;
  requestMap = {};
  connected = false;
  reserved = [];
  loginMethod = '';
  token = '';
  onclose: any = null;
  onopen: any = null;
  onerror: any = null;
  googleWebclientId = '155729315932-qful4j4i6d20rb2hsia994e6c5b9gc9j.apps.googleusercontent.com';
  serverUrl = 'https://watch2help.co.kr';
  fileServerUrl = 'https://watch2help.co.kr';
  websocketUrl = null;
  //  serverUrl = "http://127.0.0.1:8080";
  //  fileServerUrl = "http://127.0.0.1:8080";

  constructor(@Inject(DOCUMENT) private document: Document) {
    console.log(`host:${this.document.location.host}`);
    // this.open("ws://" + this.document.location.host + "/data");
    const wsUrl = this.serverUrl.replace('http', 'ws');
    this.open(wsUrl + '/ws/data');
    window.ds = this;
  }

  _onopen(e) {
    console.log('CONNECTED!\r\n');
    this.connected = true;
    this.sendReserved();
    this.doLogin(() => {
      if (this.onopen) { this.onopen(); }
    });
  }

  _onmessage(e) {
    const resp = JSON.parse(e.data);
    if (this.requestMap[resp.s]) {
      this.requestMap[resp.s](resp.c, resp.d);
      delete this.requestMap[resp.s];
    }
  }

  _onclose(e) {
    this.connected = false;
    console.log('DISCONNECTED!\r\n');
    if (this.onclose) { this.onclose(); }
    if (this.needToReconnect) {
      setTimeout(() => {
        this.reconnect();
      }, 1000);
    }
  }

  _onerror(e) {
    // this.connected = false;
    console.log('ERROR!\r\n');
    // if (this.onerror) { this.onerror(); }
    // if (this.needToReconnect) {
    //   setTimeout(() => {
    //     this.reconnect();
    //   }, 1000);
    // }
  }

  open(url) {
    this.needToReconnect = true;
    this.websocketUrl = url;

    this.reconnect();
  }

  clsoe() {
    this.needToReconnect = false;
    if (this.ws) { this.ws.close(); }
    delete this.ws;
  }

  reconnect() {
    if (this.ws) {
      this.ws.close();
      delete this.ws;
    }

    this.ws = new WebSocket(this.websocketUrl);
    this.ws.onopen = this._onopen.bind(this);
    this.ws.onmessage = this._onmessage.bind(this);
    this.ws.onclose = this._onclose.bind(this);
    this.ws.onerror = this._onerror.bind(this);
  }

  sendReserved() {
    // tslint:disable-next-line: forin
    for (const idx in this.reserved) {
      const p = this.reserved[idx];
      this.call(p.method, p.params, p.callback);
    }

    delete this.reserved;
  }

  call(method, params, callback) {
    if (!this.connected) {
      this.reserved.push({
        method,
        params,
        callback
      });
    } else {
      this.ws.send(JSON.stringify({
        t: 'req',
        s: this.seq,
        m: method,
        p: params
      }));
      this.requestMap[this.seq] = callback;
      this.seq++;
    }
  }

  get(method, params) {
    const me = this;
    return new Promise((resolve, reject) => {
      me.call(method, params, (c, d) => {
        if (c === 200) {
          resolve(d);
        } else {
          console.log(d);
          reject({code: c, error: new Error(d)});
        }
      });
    });
  }

  getFileServerUrl() {
    return this.fileServerUrl;
  }

  getAssetDir() {
    return this.fileServerUrl + '/assets';
  }

  ddpReady() {
    console.log('DDP OK');
  }

  getGoogleWebclientId() {
    return this.googleWebclientId;
  }

  setLoginTokenAndMethod(method: string, token: string) {
    this.loginMethod = method;
    this.token = token;
  }

  doLogin(callback) {
    switch (this.loginMethod) {
      case 'google':
        this.call('user.login.google', {
          token: this.token
        }, (c, d) => {
          if (c === 200) {
            callback();
          }
          console.log('response:', d);
        });
        break;
      case 'facebook':
        callback();
        break;
      default:
        callback();
        break;
    }
  }

  doLogout(successCallback, errorCallback) {
    window.plugins.googleplus.logout((e) => {
      successCallback();
    }, (e) => {
      errorCallback();
    });
  }
}
