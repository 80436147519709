import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-my-file-upload',
  templateUrl: './my-file-upload.component.html',
  styleUrls: ['./my-file-upload.component.css']
})
export class MyFileUploadComponent implements OnInit {

  @ViewChild('fileDropPreview', { static: false }) fileDropPreview: ElementRef;
  @ViewChild('fileDropName', { static: true }) fileDropName: ElementRef;
  @Input() videoSelector = false;
  @Output() selectedFileChange = new EventEmitter();
  @Input() get selectedFile() {
    return this.selectedFileValue;
  }

  set selectedFile(val: File) {
    const me = this;

    console.log('set selectedFile ', val);

    this.selectedFileValue = val;
    this.selectedFileChange.emit(this.selectedFileValue);

    if (!val) {
      setTimeout(() => {
        me.updateImage(val);
      }, 33);
    }
  }

  urlForPreview: string = null;
  selectedFileValue: File = null;

  constructor() { }

  updateImage(file) {
    if (this.urlForPreview) { URL.revokeObjectURL(this.urlForPreview); }

    if (file) {
      this.urlForPreview = URL.createObjectURL(file);
      this.fileDropPreview.nativeElement.src = this.urlForPreview;
      console.log('file: ', file.name);
      this.fileDropName.nativeElement.innerText = file.name;
    } else {
      this.urlForPreview = null;
      this.fileDropPreview.nativeElement.removeAttribute('src');
      this.fileDropName.nativeElement.innerText = 'no file';
    }
  }

  onDrop(e) {
    e.preventDefault();

    e.target.style.backgroundColor = 'white';

    for (const file of e.dataTransfer.items) {
      if (file.kind === 'file') {
        const afile = file.getAsFile();
        this.updateImage(afile);
        this.selectedFile = afile;
      }
    }
  }

  onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();

    e.target.style.backgroundColor = 'grey';
  }

  onDragLeave(e) {
    e.stopPropagation();
    e.preventDefault();

    e.target.style.backgroundColor = 'white';
  }

  onThumbnailSelected(e) {
    this.updateImage(e.target.files[0]);
    this.selectedFile = e.target.files[0];
  }

  ngOnInit() {
    const me = this;
    console.log('ngOnInit:', this);
    setTimeout(() => {
      me.updateImage(me.selectedFile);
    }, 33);
  }

  ngOnDestroy() {
    console.log('ngOnDestroy:', this);
    if (this.urlForPreview) { URL.revokeObjectURL(this.urlForPreview); }
  }
}
