import { Component, OnInit } from '@angular/core';
import {ChartInfo} from '../my-type';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {

  items: MenuItem[];
  datas: ChartInfo[] = null;
  selectedData: ChartInfo = null;
  displayDialog = false;
  
  constructor() { }

  showSingleChart(selectedData)
  {
    this.selectedData = selectedData;
    this.displayDialog = true;
  }

  ngOnInit() {
    this.items = [{
      label: '방문자',
      items: [
          {label: '오늘', icon: 'pi pi-fw pi-plus'},
          {label: '주간', icon: 'pi pi-fw pi-download'},
          {label: '월간', icon: 'pi pi-fw pi-download'}
      ]
    },
    {
      label: '시청율',
      items: [
          {label: '오늘', icon: 'pi pi-fw pi-plus'},
          {label: '주간', icon: 'pi pi-fw pi-download'},
          {label: '월간', icon: 'pi pi-fw pi-download'}
      ]
    },
    {
      label: '후원금',
      items: [
          {label: '오늘', icon: 'pi pi-fw pi-plus'},
          {label: '주간', icon: 'pi pi-fw pi-download'},
          {label: '월간', icon: 'pi pi-fw pi-download'}
      ]
    }];

    this.datas = [
      {
        name: "바 차트",
        description: "",
        type: "bar",
        data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
              {
                  label: 'My First dataset',
                  backgroundColor: '#42A5F5',
                  borderColor: '#1E88E5',
                  data: [65, 59, 80, 81, 56, 55, 40]
              },
              {
                  label: 'My Second dataset',
                  backgroundColor: '#9CCC65',
                  borderColor: '#7CB342',
                  data: [28, 48, 40, 19, 86, 27, 90]
              }
          ]
        }
      },
      {
        name: "라인 차트",
        description: "",
        type: "line",
        data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
              {
                  label: 'First Dataset',
                  data: [65, 59, 80, 81, 56, 55, 40],
                  fill: false,
                  borderColor: '#4bc0c0'
              },
              {
                  label: 'Second Dataset',
                  data: [28, 48, 40, 19, 86, 27, 90],
                  fill: false,
                  borderColor: '#565656'
              }
          ]
        }  
      },
      {
        name: "도넛 차트",
        description: "",
        type: "doughnut",
        data: {
          labels: ['A','B','C'],
          datasets: [
              {
                  data: [300, 50, 100],
                  backgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#FFCE56"
                  ],
                  hoverBackgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#FFCE56"
                  ]
              }
          ]
        }
      },
      {
        name: "파이 차트",
        description: "",
        type: "pie",
        data: {
          labels: ['A','B','C'],
          datasets: [
              {
                  data: [300, 50, 100],
                  backgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#FFCE56"
                  ],
                  hoverBackgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#FFCE56"
                  ]
              }
          ]    
        }
      },
      {
        name: "라이더 차트",
        description: "",
        type: "radar",
        data: {
          labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
          datasets: [
              {
                  label: 'My First dataset',
                  backgroundColor: 'rgba(179,181,198,0.2)',
                  borderColor: 'rgba(179,181,198,1)',
                  pointBackgroundColor: 'rgba(179,181,198,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(179,181,198,1)',
                  data: [65, 59, 90, 81, 56, 55, 40]
              },
              {
                  label: 'My Second dataset',
                  backgroundColor: 'rgba(255,99,132,0.2)',
                  borderColor: 'rgba(255,99,132,1)',
                  pointBackgroundColor: 'rgba(255,99,132,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(255,99,132,1)',
                  data: [28, 48, 40, 19, 96, 27, 100]
              }
          ]
        }
      },
      {
        name: "폴러에리어 차트",
        description: "",
        type: "polarArea",
        data: {
          datasets: [{
              data: [
                  11,
                  16,
                  7,
                  3,
                  14
              ],
              backgroundColor: [
                  "#FF6384",
                  "#4BC0C0",
                  "#FFCE56",
                  "#E7E9ED",
                  "#36A2EB"
              ],
              label: 'My dataset'
          }],
          labels: [
              "Red",
              "Green",
              "Yellow",
              "Grey",
              "Blue"
          ]
        }
      }
    ]
  }

}
