import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CompanyInfo, SessionInfo } from '../../my-type';
import { DataService } from '../../data.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})
export class CompanyDetailComponent implements OnInit {

  @Input() readonly = false;
  @Input() signupStatus = 0;
  @Input() loginType = '';
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Input() set companyId(id) {
    this.updateData(id);
  }

  userInfo: any = null;
  companyInfo: CompanyInfo = null;
  name = '';
  license = '';
  owner = '';
  businessConditions = '';
  businessCategory = '';
  zipCode = '';
  address = '';
  phone = '';
  email = '';
  applierName = '';
  applierPhone = '';

  constructor(private ds: DataService, private confirmationService: ConfirmationService) { }

  async updateData(id) {
    const companies = await this.ds.get('company.get', {id}) as CompanyInfo[];
    if (!companies || companies.length <= 0) { return; }
    this.companyInfo = companies[0];

    this.license = this.companyInfo.license;
    this.address = this.companyInfo.address;
    this.phone = this.companyInfo.phone;
    this.applierPhone = this.companyInfo.applier_phone;
    this.name = this.companyInfo.name;
    this.owner = this.companyInfo.owner;
    this.businessConditions = this.companyInfo.business_conditions;
    this.businessCategory = this.companyInfo.business_category;
    this.zipCode = this.companyInfo.zip_code;
    this.email = this.companyInfo.email;
    this.applierName = this.companyInfo.applier_name;
  }

  doSubmit() {
    const me = this;

    this.submit.emit({
      name: this.name,
      description: '',
      license: this.license,
      manager_id: this.userInfo.id,
      owner: this.owner,
      business_conditions: this.businessConditions,
      business_category: this.businessCategory,
      zip_code: this.zipCode,
      address: this.address,
      phone: this.phone,
      email: this.email,
      applier_name: this.applierName,
      applier_phone: this.applierPhone,
      picture: '',
      video: ''
    });
  }

  ngOnInit() {
  }

}
