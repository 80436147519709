import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CharityInfo, VideoInfo, MergedVideoInfo, CompanyInfo, ViewHistory } from '../../my-type';
import { DataService } from '../../data.service';
import { Observable} from 'rxjs';
import { ShowContentComponent } from '../show-content/show-content.component';
import { UploadContentComponent } from '../upload-content/upload-content.component';
import * as Chart from 'chart.js';

declare let L;

@Component({
  selector: 'app-movies',
  templateUrl: './movies.component.html',
  styleUrls: ['./movies.component.css']
})
export class MoviesComponent implements OnInit {

  constructor(private ds: DataService) { }

  videos: MergedVideoInfo[] = null;
  companies: CompanyInfo[];
  viewHistoryOfAVideo: ViewHistory[];
  selectedVideo: MergedVideoInfo;
  selectedCompany: CompanyInfo;
  assetDir = '/assets/';
  reports: any[] = null;
  displayDialog = false;
  now: Date = null;
  labels: { [key: string]: string[]} = {
    '5score': [
      'Very Bad',
      'Bad',
      'Normal',
      'Good',
      'Very Good'
    ],
    'yesno': [
      'Yes',
      'No'
    ]
  };

  @ViewChild(ShowContentComponent, { static: true }) charityDetailCmp: ShowContentComponent;
  @ViewChild(UploadContentComponent, { static: true }) uploadContentCmp: UploadContentComponent;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();


  onDoneUpload = function() {
    console.log('doneUpload');
    this.updateList();
  }.bind(this);

  openShowContentDialog(e, charity) {
    console.log(`charity:${JSON.stringify(charity)}`);
    e.stopPropagation();
    // this.charityDetailCmp.show(charity);
  }

  onDialogHide() {
    this.displayDialog = false;
  }

  openDialogForNewContent(e) {
    e.stopPropagation();
    this.uploadContentCmp.open(null);
  }

  openDialogForModify(e, data) {
    e.stopPropagation();
    this.uploadContentCmp.open(data);
  }

  getDescription(video: MergedVideoInfo) {
    if (video.description.length > 90) {
      return video.description.substring(0, 90) + '...';
    } else {
      return video.description;
    }
  }

  initValueByType(type) {
    switch (type) {
      case '5score':
        return [
          { item: 'Very Bad', value: 0 },
          { item: 'Bad', value: 0 },
          { item: 'Normal', value: 0 },
          { item: 'Good', value: 0 },
          { item: 'Very Good', value: 0 }
        ];
      case 'yesno':
        return [
          { item: 'Yes', value: 0 },
          { item: 'No', value: 0 }
        ];
    }

    return [{item: 'none', value: 0}];
  }

  async openReport(id) {
    this.now = new Date();
    this.reports = [];
    this.viewHistoryOfAVideo = await this.ds.get('view.history.getByVideoId', {video_id: id}) as ViewHistory[];
    console.log('history', this.viewHistoryOfAVideo);
    const video = this.videos.find(x => x.id === id);
    this.selectedVideo = video;
    this.selectedCompany = this.companies.find(x => x.id === video.company_id);
    video.survey.forEach(v => {
      const type = (typeof v === 'string') ? '5score' : v.type;
      this.reports.push({
        question: (typeof v === 'string') ? v : v.question,
        questionType: type,
        // type: 'horizontalBar',
        type: 'pie',
        data: this.initValueByType(type),
        // data: {
        //   datasets: [{
        //     borderWidth: 0,
        //     data: this.initValueByType(v.type),
        //     backgroundColor: ['#ff0066', '#ebebeb', '#c1c1c1', '#959595', '#44ff44']
        //   }],
        //   labels: this.labels[v.type]
        // },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          }
          // ,
          // scales: {
          //   xAxes: [{
          //     ticks: {
          //       min: 0,
          //       max: 100,
          //       stepSize: 25
          //     }
          //   }]
          // }
        }
      });
    });

    // this.viewHistoryOfAVideo.forEach(x => {
    //   for (let idx in x.survey) {
    //     try {
    //       let s = x.survey[idx];
    //       this.reports[idx].data.datasets[0].data[s-1]++;  
    //     } catch(e)
    //     {
    //       console.log(e);
    //     }
    //   }
    // });

    // this.reports.forEach(x => {
    //   for(let i in x.data.datasets[0].data) {
    //     x.data.datasets[0].data[i] = Math.round((x.data.datasets[0].data[i] / video.number_of_views) * 100);
    //   }
    // });

    this.viewHistoryOfAVideo.forEach(x => {
      for (let idx in x.survey) {
        try {
          let s = x.survey[idx];
          this.reports[idx].data[s-1].value++;  
        } catch(e)
        {
          console.log(e);
        }
      }
    });

    this.reports.forEach(x => {
      for(let i in x.data) {
        x.data[i].value = Math.round((x.data[i].value / video.number_of_views) * 100);
      }
    });


    this.displayDialog = true;
  }

  sortedList(list: any[]) {
    const sortedList = list.map( (v, i) => ({idx: i , count: v.value}));
    sortedList.sort( (i, j) => (i.count > j.count) ? -1 : 1);
    return sortedList;
  }

  // sortedList(list: []) {
  //   const sortedList = list.map( (v, i) => ({idx: i , count: v}));
  //   sortedList.sort( (i, j) => (i.count > j.count) ? -1 : 1);
  //   return sortedList;
  // }

  async updateList() {
    this.videos = await this.ds.get('video.list.manager', {}) as MergedVideoInfo[];
    this.companies = await this.ds.get('company.list', {}) as CompanyInfo[];
  }

  ngOnInit() {
    this.assetDir = this.ds.getAssetDir();
    this.updateList();
  }

}
