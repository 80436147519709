import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import { MergedVideoInfo, Summary } from '../my-type';
import { DataService } from '../data.service';

declare let L;

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.css']
})
export class DashBoardComponent implements OnInit {

  selectedAd = null;
  videos: MergedVideoInfo[] = [];
  summary: Summary = null;
  map: any;
  markers: any = null;
  latlng: any = null;
  data1: number[] = [];
  data2: number[] = [];
  data3: number[] = [];
  data4: number[] = [];

  constructor(private ds: DataService) { }

  drawMarkers() {
    if (this.markers != null) {
      this.map.removeLayer(this.markers);
    }

    this.markers = L.markerClusterGroup(
      {
        chunkedLoading: true,
        maxClusterRadius: 40 }
      );

    this.latlng.forEach(x => {
      const icon = L.divIcon( {
        iconSize: [20, 20],
        className: 'my_icon',
        html:
        '<div style="position: relative; width: 100%; height: 100%; border-radius: 50%; background-color: #ff0000A0;"><div style="position: absolute; left: 10%; top: 10%; width: 80%; height: 80%; background-color: white; border-radius: 50%; display: flex; align-items: center; text-align: center; justify-content: center; font-size: 2em; color: red; line-height: 120%;"></div></div>'
      });
      const marker = L.marker(L.latLng(x.lat, x.lng), {icon, title: x.name });
      this.markers.addLayer(marker);
    });

    this.map.addLayer(this.markers);
  }

  drawChart1() {
    const doc = document.getElementById('myChart1') as HTMLCanvasElement;
    const ctx = doc.getContext('2d');

    const myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          borderWidth: 0,
          data: this.data1,
          backgroundColor: ['#ff0066', '#ebebeb', '#c1c1c1', '#959595', '#636363']
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [
            '바로 설문',
            '광고후 이탈',
            '바로 이탈',
            '시청 안함',
            '기타'
        ]
      },
      options: {
        cutoutPercentage: 75,
        legend: {
          display: false
        }
      }
    });
  }

  drawChart2() {
    const doc = document.getElementById('myChart2') as HTMLCanvasElement;
    const ctx = doc.getContext('2d');

    const myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          borderWidth: 0,
          data: this.data2,
          backgroundColor: ['#ff0066', '#ebebeb', '#c1c1c1', '#959595', '#636363']
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [
            '30대',
            '40대',
            '20대',
            '50대',
            '기타'
        ]
      },
      options: {
        cutoutPercentage: 75,
        legend: {
          display: false
        }
      }
    });
  }

  drawChart3() {
    const doc = document.getElementById('myChart3') as HTMLCanvasElement;
    const ctx = doc.getContext('2d');

    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: 'Dataset 1',
          data: [
            {t: new Date('2019-1-15'), y: 10},
            {t: new Date('2019-2-15'), y: 20},
            {t: new Date('2019-3-15'), y: 15},
            {t: new Date('2019-4-15'), y: 40},
            {t: new Date('2019-5-15'), y: 45},
            {t: new Date('2019-6-15'), y: 40},
            {t: new Date('2019-7-15'), y: 60},
            {t: new Date('2019-8-15'), y: 65},
            {t: new Date('2019-9-15'), y: 70}
          ],
          borderColor: '#d8d8d8',
          fill: false
        },
        {
          label: 'Dataset 2',
          data: [
            {t: new Date('2019-1-15'), y: this.data3[0]},
            {t: new Date('2019-2-15'), y: this.data3[1]},
            {t: new Date('2019-3-15'), y: this.data3[2]},
            {t: new Date('2019-4-15'), y: this.data3[3]},
            {t: new Date('2019-5-15'), y: this.data3[4]},
            {t: new Date('2019-6-15'), y: this.data3[5]},
            {t: new Date('2019-7-15'), y: this.data3[6]},
            {t: new Date('2019-8-15'), y: this.data3[7]},
            {t: new Date('2019-9-15'), y: this.data3[8]}
          ],
          borderColor: '#ff0066',
          fill: false
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: 100,
              stepSize: 25
            },
            gridLines: {
              display: true,
              borderDash: [4, 2],
              zeroLineBorderDash: [4, 2],
              drawBorder: false
            }
          }],
          xAxes: [
            {
              type: 'time',
              gridLines: {
                display: false,
                drawBorder: false
              },
              time: {
                unit: 'month',
                displayFormats: {
                  month: 'MM'
                }
              }
            }
          ]
        }
      }
    });
  }

  async updateData() {
    this.videos = await this.ds.get('video.list', {}) as MergedVideoInfo[];
    console.log('videos: ', this.videos);
    this.summary = (await this.ds.get('summary', {}) as Summary[])[0];
  }

  selectedAdChanged(ad) {
    console.log("ad=", ad);
    let key = (this.selectedAd == null)?0: this.selectedAd.id;

    switch(key) {
      case 0:
        this.latlng = [
          {
            lat: 37.59698109257849,
            lng: 127.01191351471356,
            name: '서울',
            value: 53
          },
          {lat: 37.458424775408126, lng: 127.03785292815972, name: '광고', value: 1},
          {lat: 37.517973741310385, lng: 127.0735689301454, name: '광고', value: 1},
          {lat: 37.56151605749468, lng: 127.3235812374977, name: '광고', value: 1},
          {lat: 37.44607334480539, lng: 127.34281295344788, name: '광고', value: 1},
          {lat: 37.36101098018257, lng: 127.29885474556177, name: '광고', value: 1},
          {lat: 37.36101098018257, lng: 127.49666668104932, name: '광고', value: 1},
          {lat: 37.142461480662234, lng: 127.14225362996746, name: '광고', value: 1},
          {lat: 37.24307298524453, lng: 126.90597826257954, name: '광고', value: 1},
          {lat: 37.47004783104199, lng: 126.74937714698525, name: '광고', value: 1},
          {lat: 37.73325947199959, lng: 126.72739804304219, name: '광고', value: 1},
          {lat: 37.90893815860598, lng: 126.99664206634466, name: '광고', value: 1},
          {lat: 37.841752501153024, lng: 127.24940176168988, name: '광고', value: 1},
          {lat: 37.80054391428202, lng: 127.57359354485003, name: '광고', value: 1},
          {lat: 37.572397663083585, lng: 127.77964764431621, name: '광고', value: 1},
          {
            lat: 37.73040536473621,
            lng: 128.35256681727498,
            name: '강원',
            value: 2
          },
          {lat: 37.68345878868386, lng: 128.44541133926393, name: '광고', value: 1},
          {lat: 37.666073449293926, lng: 128.28606283567674, name: '광고', value: 1},
          {lat: 37.802872683999475, lng: 128.26408373173368, name: '광고', value: 1},
          {lat: 37.898265024281216, lng: 128.4179374593351, name: '광고', value: 1},
          {lat: 37.848416241671096, lng: 128.54981208299344, name: '광고', value: 1},
          {lat: 37.7833454133178, lng: 128.4316743992995, name: '광고', value: 1},
          {lat: 37.70952915920014, lng: 128.68443409464473, name: '광고', value: 1},
          {lat: 37.64216195707544, lng: 128.59102290288672, name: '광고', value: 1},
          {lat: 37.48546032558857, lng: 128.52233820306463, name: '광고', value: 1},
          {lat: 37.518133676734585, lng: 128.37397925144896, name: '광고', value: 1},
          {lat: 37.426612271183224, lng: 128.27232589571233, name: '광고', value: 1},
          {lat: 37.54426206177489, lng: 128.22562029983334, name: '광고', value: 1},
          {lat: 37.63129055171328, lng: 128.06352440825324, name: '광고', value: 1},
          {lat: 37.65520554373785, lng: 128.2448520157835, name: '광고', value: 1},
          {lat: 37.939419007470335, lng: 128.0525348562817, name: '광고', value: 1},
          {lat: 38.05193316398172, lng: 128.08550351219628, name: '광고', value: 1},
          {lat: 37.98054995922113, lng: 128.17067253997567, name: '광고', value: 1},
          {
            lat: 37.20716310449693,
            lng: 127.62756180650027,
            name: '경기',
            value: 13
          },
          {lat: 37.24479632646809, lng: 127.60584203212407, name: '광고', value: 1},
          {lat: 37.162788048498, lng: 127.68139520192834, name: '광고', value: 1},
          {lat: 37.226215593873725, lng: 127.68139520192834, name: '광고', value: 1},
          {lat: 37.21419032673065, lng: 127.56463121223084, name: '광고', value: 1},
          {lat: 37.125581615714815, lng: 127.56188382423795, name: '광고', value: 1},
          {lat: 37.177009200235645, lng: 127.50418867638743, name: '광고', value: 1},
          {lat: 37.27211261290488, lng: 127.54539949628062, name: '광고', value: 1},
          {lat: 37.29395850879455, lng: 127.72123232782516, name: '광고', value: 1},
          {lat: 37.19232129345629, lng: 127.7734326996899, name: '광고', value: 1},
          {lat: 37.07083303368685, lng: 127.72672710381087, name: '광고', value: 1},
          {lat: 37.06206958958382, lng: 127.62919483006358, name: '광고', value: 1},
          {lat: 37.1036869305099, lng: 127.57836815219525, name: '광고', value: 1},
          {lat: 37.10916119533677, lng: 127.49457281841231, name: '광고', value: 1},
          {lat: 37.149658456232, lng: 127.5508942722664, name: '광고', value: 1},
          {
            lat: 36.34469732599981,
            lng: 127.3968180340502,
            name: '대전',
            value: 9
          },
          {lat: 36.31386763838188, lng: 127.36737259036498, name: '광고', value: 1},
          {lat: 36.370263647446684, lng: 127.33989871043615, name: '광고', value: 1},
          {lat: 36.38573775959347, lng: 127.40858341025819, name: '광고', value: 1},
          {lat: 36.33045893905672, lng: 127.460783782123, name: '광고', value: 1},
          {lat: 36.25964478074007, lng: 127.40720971626175, name: '광고', value: 1},
          {lat: 36.23971671940537, lng: 127.25060860066746, name: '광고', value: 1},
          {lat: 36.321610684905, lng: 127.2258821087315, name: '광고', value: 1},
          {lat: 36.485140655466054, lng: 127.33440393445036, name: '광고', value: 1},
          {lat: 36.514936691820175, lng: 127.55968974986673, name: '광고', value: 1},
          {lat: 36.47299824268698, lng: 127.52946848194503, name: '광고', value: 1},
          {lat: 36.394578727051, lng: 127.50886307199839, name: '광고', value: 1},
          {lat: 36.4752060956206, lng: 127.66409049359629, name: '광고', value: 1},
          {lat: 36.370263647446684, lng: 127.65584832961765, name: '광고', value: 1},
          {lat: 36.31608001587394, lng: 127.51298415398776, name: '광고', value: 1},
          {lat: 36.24857426297078, lng: 127.62837444968882, name: '광고', value: 1},
          {lat: 36.22642852203681, lng: 127.47726811008027, name: '광고', value: 1},
          {lat: 36.10007806977516, lng: 127.52534739995572, name: '광고', value: 1},
          {
            lat: 35.15876129421654,
            lng: 129.09948131693017,
            name: '부산',
            value: 11
          },
          {lat: 35.18946348313678, lng: 129.12067144217784, name: '광고', value: 1},
          {lat: 35.17151037745336, lng: 129.05336043635222, name: '광고', value: 1},
          {lat: 35.17824325678071, lng: 128.97368618455863, name: '광고', value: 1},
          {lat: 35.163654646858, lng: 128.93659644665473, name: '광고', value: 1},
          {lat: 35.21077764505915, lng: 128.91736473070452, name: '광고', value: 1},
          {lat: 35.21750727091615, lng: 128.97505987855507, name: '광고', value: 1},
          {lat: 35.1187502024304, lng: 129.00528114647676, name: '광고', value: 1},
          {lat: 35.07494452504781, lng: 128.97505987855507, name: '광고', value: 1},
          {lat: 35.09965831361781, lng: 128.9091225667259, name: '광고', value: 1},
          {lat: 35.0929189321335, lng: 128.85829588885755, name: '광고', value: 1},
          {lat: 35.03673576199736, lng: 128.82670092693942, name: '광고', value: 1},
          {lat: 35.10864328902485, lng: 128.79373227102482, name: '광고', value: 1},
          {lat: 35.14232812451179, lng: 128.83494309091807, name: '광고', value: 1},
          {lat: 35.209655986512935, lng: 128.83219570292516, name: '광고', value: 1},
          {lat: 35.24554137298181, lng: 128.95445446860845, name: '광고', value: 1},
          {lat: 35.2679616786596, lng: 129.1357820761387, name: '광고', value: 1},
          {lat: 35.23432889465671, lng: 129.186608754007, name: '광고', value: 1},
          {lat: 35.26908253113617, lng: 129.24430390185756, name: '광고', value: 1},
          {lat: 35.32398530217559, lng: 129.18386136601416, name: '광고', value: 1},
          {
            lat: 35.82969595184447,
            lng: 128.55023509664628,
            name: '대구',
            value: 3
          },
          {lat: 35.847915383887006, lng: 128.5430230631148, name: '광고', value: 1},
          {lat: 35.82621321645069, lng: 128.53546774613437, name: '광고', value: 1},
          {lat: 35.83567386547572, lng: 128.50387278421618, name: '광고', value: 1},
          {lat: 35.813968349369325, lng: 128.53958882812367, name: '광고', value: 1},
          {lat: 35.80005143521014, lng: 128.5073070192073, name: '광고', value: 1},
          {lat: 35.801164878082176, lng: 128.54439675711123, name: '광고', value: 1},
          {lat: 35.781120518843494, lng: 128.57118379004186, name: '광고', value: 1},
          {lat: 35.80784520757861, lng: 128.56843640204895, name: '광고', value: 1},
          {lat: 35.78557525780782, lng: 128.58835496499734, name: '광고', value: 1},
          {lat: 35.82231732725649, lng: 128.60277875196, name: '광고', value: 1},
          {lat: 35.831221935850074, lng: 128.56912324904718, name: '광고', value: 1},
          {lat: 35.847915383887006, lng: 128.58217334201336, name: '광고', value: 1},
          {lat: 35.85014091152555, lng: 128.5512652270934, name: '광고', value: 1},
          {lat: 35.88240404254017, lng: 128.5670627080525, name: '광고', value: 1},
          {lat: 35.887965323705025, lng: 128.6522317358319, name: '광고', value: 1},
          {lat: 35.81953443218548, lng: 128.67970561576067, name: '광고', value: 1},
          {lat: 35.73433069018738, lng: 128.590415505992, name: '광고', value: 1},
          {lat: 35.73934520212216, lng: 128.45854088233366, name: '광고', value: 1},
          {lat: 35.87016784938357, lng: 128.42831961441192, name: '광고', value: 1},
          {lat: 35.93577621743439, lng: 128.4276327674137, name: '광고', value: 1},
          {
            lat: 33.33939766998252,
            lng: 126.52534758802199,
            name: '제주',
            value: 2
          },
          {
            lat: 35.15576705583416,
            lng: 126.85489532019231,
            name: '광주',
            value: 4
          },
          {
            lat: 36.73313198025168,
            lng: 126.93545120901175,
            name: '충청',
            value: 4
          },
          {
            lat: 36.627388841899325,
            lng: 128.934707450845,
            name: '경북',
            value: 4
          },
          {
            lat: 35.70488050276715,
            lng: 127.22105924355937,
            name: '전북',
            value: 5
          },
          {
            lat: 35.29339610276727,
            lng: 128.24265721328734,
            name: '경남',
            value: 3
          }
        ];
        this.data1 = [62.7, 26.8, 9.5, 0.8, 0.2];
        this.data2 = [64.9, 33.1, 9.0, 2.0, 1.0];
        this.data3 = [20, 15, 20, 30, 35, 50, 55, 70, 75];
        this.data4 = [75, 25];    
        break;
        case 36: //리바트와 스와레를 리스펙!
          this.latlng = [
            {
              lat: 37.59698109257849,
              lng: 127.01191351471356,
              name: '서울',
              value: 53
            },  
            {
              lat: 37.73040536473621,
              lng: 128.35256681727498,
              name: '강원',
              value: 2
            },
            {
              lat: 37.20716310449693,
              lng: 127.62756180650027,
              name: '경기',
              value: 13
            },
            {
              lat: 36.34469732599981,
              lng: 127.3968180340502,
              name: '대전',
              value: 9
            },
            {
              lat: 35.15876129421654,
              lng: 129.09948131693017,
              name: '부산',
              value: 11
            },
            {
              lat: 35.82969595184447,
              lng: 128.55023509664628,
              name: '대구',
              value: 3
            },        
            {
              lat: 33.33939766998252,
              lng: 126.52534758802199,
              name: '제주',
              value: 2
            },
            {
              lat: 35.15576705583416,
              lng: 126.85489532019231,
              name: '광주',
              value: 4
            },
            {
              lat: 36.73313198025168,
              lng: 126.93545120901175,
              name: '충청',
              value: 4
            },
            {
              lat: 36.627388841899325,
              lng: 128.934707450845,
              name: '경북',
              value: 4
            },
            {
              lat: 35.70488050276715,
              lng: 127.22105924355937,
              name: '전북',
              value: 5
            },
            {
              lat: 35.29339610276727,
              lng: 128.24265721328734,
              name: '경남',
              value: 3
            }  
          ];
          this.data1 = [63.7, 20.8, 8.5, 6.8, 0.2];
          this.data2 = [60.9, 23.1, 13.0, 2.0, 11.0];
          this.data3 = [20, 25, 15, 25, 30, 60, 50, 60, 65];
          this.data4 = [55, 45];
        break;
        case 34: //탁 트인 조망과 넓은 뷰를 감상하고 싶다면
          this.latlng = [
            {
              lat: 37.59698109257849,
              lng: 127.01191351471356,
              name: '서울',
              value: 53
            },
            {lat: 37.458424775408126, lng: 127.03785292815972, name: '광고', value: 1},
            {lat: 37.517973741310385, lng: 127.0735689301454, name: '광고', value: 1},
            {lat: 37.56151605749468, lng: 127.3235812374977, name: '광고', value: 1},
            {lat: 37.44607334480539, lng: 127.34281295344788, name: '광고', value: 1},
            {lat: 37.36101098018257, lng: 127.29885474556177, name: '광고', value: 1},
            {lat: 37.36101098018257, lng: 127.49666668104932, name: '광고', value: 1},
            {lat: 37.142461480662234, lng: 127.14225362996746, name: '광고', value: 1},
            {lat: 37.24307298524453, lng: 126.90597826257954, name: '광고', value: 1},
            {lat: 37.47004783104199, lng: 126.74937714698525, name: '광고', value: 1},
            {lat: 37.73325947199959, lng: 126.72739804304219, name: '광고', value: 1},
            {lat: 37.90893815860598, lng: 126.99664206634466, name: '광고', value: 1},
            {lat: 37.841752501153024, lng: 127.24940176168988, name: '광고', value: 1},
            {lat: 37.80054391428202, lng: 127.57359354485003, name: '광고', value: 1},
            {lat: 37.572397663083585, lng: 127.77964764431621, name: '광고', value: 1},
            {
              lat: 37.73040536473621,
              lng: 128.35256681727498,
              name: '강원',
              value: 2
            },
            {lat: 37.68345878868386, lng: 128.44541133926393, name: '광고', value: 1},
            {lat: 37.666073449293926, lng: 128.28606283567674, name: '광고', value: 1},
            {lat: 37.802872683999475, lng: 128.26408373173368, name: '광고', value: 1},
            {lat: 37.898265024281216, lng: 128.4179374593351, name: '광고', value: 1},
            {lat: 37.848416241671096, lng: 128.54981208299344, name: '광고', value: 1},
            {lat: 37.7833454133178, lng: 128.4316743992995, name: '광고', value: 1},
            {lat: 37.70952915920014, lng: 128.68443409464473, name: '광고', value: 1},
            {lat: 37.64216195707544, lng: 128.59102290288672, name: '광고', value: 1},
            {lat: 37.48546032558857, lng: 128.52233820306463, name: '광고', value: 1},
            {lat: 37.518133676734585, lng: 128.37397925144896, name: '광고', value: 1},
            {lat: 37.426612271183224, lng: 128.27232589571233, name: '광고', value: 1},
            {lat: 37.54426206177489, lng: 128.22562029983334, name: '광고', value: 1},
            {lat: 37.63129055171328, lng: 128.06352440825324, name: '광고', value: 1},
            {lat: 37.65520554373785, lng: 128.2448520157835, name: '광고', value: 1},
            {lat: 37.939419007470335, lng: 128.0525348562817, name: '광고', value: 1},
            {lat: 38.05193316398172, lng: 128.08550351219628, name: '광고', value: 1},
            {lat: 37.98054995922113, lng: 128.17067253997567, name: '광고', value: 1},
          ];
          this.data1 = [60.7, 13.8, 18.5, 4.8, 2.2];
          this.data2 = [50.9, 33.1, 10.0, 5.0, 11.0];
          this.data3 = [20, 22, 20, 25, 33, 40, 50, 55, 60];
          this.data4 = [45, 55];
        break;
        case 35: //캐리어에어컨의 윌리스 캐리어 박사가 현대인에게
          this.latlng = [
            {
              lat: 37.20716310449693,
              lng: 127.62756180650027,
              name: '경기',
              value: 13
            },
            {lat: 37.24479632646809, lng: 127.60584203212407, name: '광고', value: 1},
            {lat: 37.162788048498, lng: 127.68139520192834, name: '광고', value: 1},
            {lat: 37.226215593873725, lng: 127.68139520192834, name: '광고', value: 1},
            {lat: 37.21419032673065, lng: 127.56463121223084, name: '광고', value: 1},
            {lat: 37.125581615714815, lng: 127.56188382423795, name: '광고', value: 1},
            {lat: 37.177009200235645, lng: 127.50418867638743, name: '광고', value: 1},
            {lat: 37.27211261290488, lng: 127.54539949628062, name: '광고', value: 1},
            {lat: 37.29395850879455, lng: 127.72123232782516, name: '광고', value: 1},
            {lat: 37.19232129345629, lng: 127.7734326996899, name: '광고', value: 1},
            {lat: 37.07083303368685, lng: 127.72672710381087, name: '광고', value: 1},
            {lat: 37.06206958958382, lng: 127.62919483006358, name: '광고', value: 1},
            {lat: 37.1036869305099, lng: 127.57836815219525, name: '광고', value: 1},
            {lat: 37.10916119533677, lng: 127.49457281841231, name: '광고', value: 1},
            {lat: 37.149658456232, lng: 127.5508942722664, name: '광고', value: 1},
            {
              lat: 36.34469732599981,
              lng: 127.3968180340502,
              name: '대전',
              value: 9
            },
            {lat: 36.31386763838188, lng: 127.36737259036498, name: '광고', value: 1},
            {lat: 36.370263647446684, lng: 127.33989871043615, name: '광고', value: 1},
            {lat: 36.38573775959347, lng: 127.40858341025819, name: '광고', value: 1},
            {lat: 36.33045893905672, lng: 127.460783782123, name: '광고', value: 1},
            {lat: 36.25964478074007, lng: 127.40720971626175, name: '광고', value: 1},
            {lat: 36.23971671940537, lng: 127.25060860066746, name: '광고', value: 1},
            {lat: 36.321610684905, lng: 127.2258821087315, name: '광고', value: 1},
            {lat: 36.485140655466054, lng: 127.33440393445036, name: '광고', value: 1},
            {lat: 36.514936691820175, lng: 127.55968974986673, name: '광고', value: 1},
            {lat: 36.47299824268698, lng: 127.52946848194503, name: '광고', value: 1},
            {lat: 36.394578727051, lng: 127.50886307199839, name: '광고', value: 1},
            {lat: 36.4752060956206, lng: 127.66409049359629, name: '광고', value: 1},
            {lat: 36.370263647446684, lng: 127.65584832961765, name: '광고', value: 1},
            {lat: 36.31608001587394, lng: 127.51298415398776, name: '광고', value: 1},
            {lat: 36.24857426297078, lng: 127.62837444968882, name: '광고', value: 1},
            {lat: 36.22642852203681, lng: 127.47726811008027, name: '광고', value: 1},
            {lat: 36.10007806977516, lng: 127.52534739995572, name: '광고', value: 1},
          ];
          this.data1 = [65.7, 11.8, 13.5, 6.8, 2.2];
          this.data2 = [47.0, 37.0, 9.0, 6.0, 11.0];
          this.data3 = [20, 22, 30, 29, 39, 60, 50, 55, 63];
          this.data4 = [40, 60];
        break;
        case 33: //RMHC X GS25 소중한 기부 함께해요
          this.latlng = [
            {
              lat: 35.15876129421654,
              lng: 129.09948131693017,
              name: '부산',
              value: 11
            },
            {lat: 35.18946348313678, lng: 129.12067144217784, name: '광고', value: 1},
            {lat: 35.17151037745336, lng: 129.05336043635222, name: '광고', value: 1},
            {lat: 35.17824325678071, lng: 128.97368618455863, name: '광고', value: 1},
            {lat: 35.163654646858, lng: 128.93659644665473, name: '광고', value: 1},
            {lat: 35.21077764505915, lng: 128.91736473070452, name: '광고', value: 1},
            {lat: 35.21750727091615, lng: 128.97505987855507, name: '광고', value: 1},
            {lat: 35.1187502024304, lng: 129.00528114647676, name: '광고', value: 1},
            {lat: 35.07494452504781, lng: 128.97505987855507, name: '광고', value: 1},
            {lat: 35.09965831361781, lng: 128.9091225667259, name: '광고', value: 1},
            {lat: 35.0929189321335, lng: 128.85829588885755, name: '광고', value: 1},
            {lat: 35.03673576199736, lng: 128.82670092693942, name: '광고', value: 1},
            {lat: 35.10864328902485, lng: 128.79373227102482, name: '광고', value: 1},
            {lat: 35.14232812451179, lng: 128.83494309091807, name: '광고', value: 1},
            {lat: 35.209655986512935, lng: 128.83219570292516, name: '광고', value: 1},
            {lat: 35.24554137298181, lng: 128.95445446860845, name: '광고', value: 1},
            {lat: 35.2679616786596, lng: 129.1357820761387, name: '광고', value: 1},
            {lat: 35.23432889465671, lng: 129.186608754007, name: '광고', value: 1},
            {lat: 35.26908253113617, lng: 129.24430390185756, name: '광고', value: 1},
            {lat: 35.32398530217559, lng: 129.18386136601416, name: '광고', value: 1},
            {
              lat: 35.82969595184447,
              lng: 128.55023509664628,
              name: '대구',
              value: 3
            },
            {lat: 35.847915383887006, lng: 128.5430230631148, name: '광고', value: 1},
            {lat: 35.82621321645069, lng: 128.53546774613437, name: '광고', value: 1},
            {lat: 35.83567386547572, lng: 128.50387278421618, name: '광고', value: 1},
            {lat: 35.813968349369325, lng: 128.53958882812367, name: '광고', value: 1},
            {lat: 35.80005143521014, lng: 128.5073070192073, name: '광고', value: 1},
            {lat: 35.801164878082176, lng: 128.54439675711123, name: '광고', value: 1},
            {lat: 35.781120518843494, lng: 128.57118379004186, name: '광고', value: 1},
            {lat: 35.80784520757861, lng: 128.56843640204895, name: '광고', value: 1},
            {lat: 35.78557525780782, lng: 128.58835496499734, name: '광고', value: 1},
            {lat: 35.82231732725649, lng: 128.60277875196, name: '광고', value: 1},
            {lat: 35.831221935850074, lng: 128.56912324904718, name: '광고', value: 1},
            {lat: 35.847915383887006, lng: 128.58217334201336, name: '광고', value: 1},
            {lat: 35.85014091152555, lng: 128.5512652270934, name: '광고', value: 1},
            {lat: 35.88240404254017, lng: 128.5670627080525, name: '광고', value: 1},
            {lat: 35.887965323705025, lng: 128.6522317358319, name: '광고', value: 1},
            {lat: 35.81953443218548, lng: 128.67970561576067, name: '광고', value: 1},
            {lat: 35.73433069018738, lng: 128.590415505992, name: '광고', value: 1},
            {lat: 35.73934520212216, lng: 128.45854088233366, name: '광고', value: 1},
            {lat: 35.87016784938357, lng: 128.42831961441192, name: '광고', value: 1},
            {lat: 35.93577621743439, lng: 128.4276327674137, name: '광고', value: 1},
          ];
          this.data1 = [45.7, 31.8, 10.5, 6.8, 5.2];
          this.data2 = [40.0, 37.0, 16.0, 6.0, 11.0];
          this.data3 = [30, 29, 35, 36, 60, 65, 65, 70, 75];
          this.data4 = [70, 30];
        break;
    }

    this.drawMarkers();
    this.drawChart1();
    this.drawChart2();
    this.drawChart3();
  }

  createMap() {
    this.map = L.map('map', {
      zoomControl: true
    }).setView([36.52470308242787, 127.29046928310394], 7);

    // L.tileLayer('http://xdworld.vworld.kr:8080/2d/Base/service/{z}/{x}/{y}.png', {
    //     attribution: ''
    // }).addTo(map);

    L.tileLayer('https://xdworld.vworld.kr/2d/gray/service/{z}/{x}/{y}.png', {
        attribution: ''
    }).addTo(this.map);

    // data.forEach(x => {
    //   const size = 40 + (60 * x.value) / 100;
    //   const icon = L.divIcon( {
    //     iconSize: [size, size],
    //     className: 'my_icon',
    //     html:
    //     '<div style="position: relative; width: 100%; height: 100%; border-radius: 50%; background-color: #ffffffA0;"><div style="position: absolute; left: 10%; top: 10%; width: 80%; height: 80%; background-color: white; border-radius: 50%; display: flex; align-items: center; text-align: center; justify-content: center; font-size: 2em; color: red; line-height: 120%;">'
    //      + x.value + '</div></div>'
    //   });
    //   L.marker([x.lat, x.lng], {icon: icon}).addTo(map);
    // });

    this.map.on('click', function(e) {
      console.log(`{'lat':${e.latlng.lat}, 'lng':${e.latlng.lng}, 'name': '광고', 'value': 1}`);
    });
  }

  ngOnInit() {
    this.updateData();
    this.createMap();
    this.selectedAdChanged(null);
    this.drawMarkers();
    this.drawChart1();
    this.drawChart2();
    this.drawChart3();
  }

}
