import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import {MessageService, ConfirmationService} from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../data.service';
import { CompanyInfo, CharityInfo } from '../../my-type';

@Component({
  selector: 'app-upload-charity-video',
  templateUrl: './upload-charity-video.component.html',
  styleUrls: ['./upload-charity-video.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UploadCharityVideoComponent implements OnInit {

  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('ThumbnailPreview', { static: false }) ThumbnailPreview: ElementRef;
  @ViewChild('VideoPreview', { static: false }) VideoPreview: ElementRef;
  @ViewChild('spinnerEle', { static: false }) spinnerEle: ElementRef;
  @Input() charityId: number;

  assetDir = '/assets/';

  urlForThumbnailPreview: string = null;
  urlForVideoPreview: string = null;
  selectedThumbnailFiles: File = null;
  selectedVideoFiles: File = null;
  thumbnailFile: string = null;
  videoFile: string = null;
  name = '';
  description = '';
  isEditMode = false;

  constructor(private httpClient: HttpClient, private ds: DataService,
    private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.assetDir = this.ds.getAssetDir();
    this.update();
  }

  async update() {
    const me = this;
    if (this.charityId && this.charityId !== 0) {
      this.ds.call('charity.get', {id : this.charityId}, (c, d: CharityInfo) => {
        if (c === 200) {
          console.log(d);
          me.open(d[0]);
        }
      });
    } else {
      me.open(null);
    }
  }

  open(data: CharityInfo) {
    const me = this;

    if (data) {
      this.isEditMode = true;
      this.charityId = data.id;
      this.name = data.name;
      this.description = data.description;
      this.thumbnailFile = data.thumbnail;
      this.videoFile = data.video;

      if (this.thumbnailFile) {
        this.urlForThumbnailPreview = me.assetDir + '/images/' + me.thumbnailFile;
        setTimeout(() => {
          me.ThumbnailPreview.nativeElement.src = me.urlForThumbnailPreview;
        }, 100);
      }

      if (this.videoFile) {
        this.urlForVideoPreview = me.assetDir + '/videos/' + me.videoFile;
        setTimeout(() => {
          me.VideoPreview.nativeElement.src = me.urlForVideoPreview;
        }, 100);
      }
    } else {
      this.isEditMode = false;

      this.charityId = 0;
      this.name = '';
      this.description = '';
      this.thumbnailFile = null;
      this.videoFile = null;
    }
  }

  delete() {
    const me = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to erase this item?',
      accept: () => {
        this.ds.call('charity.delete',
        {
          id: this.charityId
        },
        (c, d) => {
          if (c === 200) {
            me.itemSelected.emit({menu: 'charity'});
          }
        });
      }
    });
  }

  onVideoSelected(e) {
    const file = e.target.files[0];
    const me = this;

    if (this.urlForVideoPreview) { URL.revokeObjectURL(this.urlForVideoPreview); }

    if (file) {
      this.selectedVideoFiles = file;
      this.urlForVideoPreview = URL.createObjectURL(file);
      setTimeout(() => {
        this.VideoPreview.nativeElement.src = this.urlForVideoPreview;
      }, 100);
      console.log('file: ', file.name);
      // this.fileDropName.nativeElement.innerText = file.name;
    } else {
      this.urlForVideoPreview = null;
      setTimeout(() => {
        this.VideoPreview.nativeElement.removeAttribute('src');
      }, 100);
      // this.fileDropName.nativeElement.innerText = 'no file';
    }
  }

  onThumbnailSelected(e) {
    const file = e.target.files[0];
    const me = this;

    if (this.urlForThumbnailPreview) { URL.revokeObjectURL(this.urlForThumbnailPreview); }

    if (file) {
      this.selectedThumbnailFiles = file;
      this.urlForThumbnailPreview = URL.createObjectURL(file);
      setTimeout(() => {
        me.ThumbnailPreview.nativeElement.src = me.urlForThumbnailPreview;
      }, 100);
      console.log('file: ', file.name);
      // this.fileDropName.nativeElement.innerText = file.name;
    } else {
      this.urlForThumbnailPreview = null;
      setTimeout(() => {
        me.ThumbnailPreview.nativeElement.removeAttribute('src');
      }, 100);
      // this.fileDropName.nativeElement.innerText = 'no file';
    }
  }

  removeVideo() {
    this.urlForVideoPreview = null;
    this.selectedVideoFiles = null;
    this.videoFile = null;
  }

  removeThumbnail() {
    this.urlForThumbnailPreview = null;
    this.selectedThumbnailFiles = null;
    this.thumbnailFile = null;
  }

  onUpload() {
    const me = this;
    if (this.thumbnailFile != null && this.videoFile != null) {
      const survey: any[] = [];

      const payload: CharityInfo = {
        id: this.charityId,
        name: this.name,
        description: this.description,
        thumbnail: this.thumbnailFile,
        video: this.videoFile
      };

      this.ds.call((this.isEditMode) ? 'charity.set' : 'charity.add', payload, (c, d: CharityInfo[]) => {
        me.spinnerEle.nativeElement.style.display = 'none';
        if (c === 200) {
          me.itemSelected.emit({menu: 'charity'});
        } else {
          // TODO: 에러 메시지 표시 필요
        }
      });
    }
  }

  doVideoFileUpload(file) {
    const me = this;
    console.log('file:', file);

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    this.httpClient
        .post<any>(this.ds.getFileServerUrl() + '/upload', formData)
        .subscribe(r => {
          console.log('videoFile:', r);
          me.videoFile = r.file;
          me.onUpload();
        });
  }

  doThumbnailFileUpload(file) {
    const me = this;
    console.log('file:', file);

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    this.httpClient
        .post<any>(this.ds.getFileServerUrl() + '/upload', formData)
        .subscribe(r => {
          console.log('thumbnailFile:', r);
          me.thumbnailFile = r.file;
          me.onUpload();
        });
  }

  apply() {
    if (!this.urlForThumbnailPreview && !this.thumbnailFile) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '썸네일', detail: '썸네일을 선택해주세요.'});
      return;
    }

    if (!this.urlForVideoPreview && !this.videoFile) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '동영상', detail: '동영상을 선택해주세요.'});
      return;
    }

    if (this.name == null || this.name.trim().length === 0) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '이름', detail: '이름을 입력해주세요.'});
      return;
    }

    if (this.description == null || this.description.trim().length === 0) {
      this.messageService.add({key: 'tc', severity: 'error', summary: '내용', detail: '내용을 입력해주세요.'});
      return;
    }

    this.spinnerEle.nativeElement.style.display = 'block';

    if (!this.selectedVideoFiles && !this.selectedThumbnailFiles) {
      this.onUpload();
    } else {
      if (this.selectedThumbnailFiles) { this.thumbnailFile = null; }
      if (this.selectedVideoFiles) { this.videoFile = null; }
    }

    if (this.selectedVideoFiles) { this.doVideoFileUpload(this.selectedVideoFiles); }
    if (this.selectedThumbnailFiles) { this.doThumbnailFileUpload(this.selectedThumbnailFiles); }
  }

}
