import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

declare const G2: any;

@Component({
  selector: 'app-g2-chart',
  templateUrl: './g2-chart.component.html',
  styleUrls: ['./g2-chart.component.css']
})
export class G2ChartComponent implements OnInit {

  @Input() type: string;
  @Input() data: any;
  @Input() options: any;
  @Input() canvasWidth: string;
  @Input() canvasHeight: string;
  @ViewChild('myChart', {static: true}) myChart: ElementRef;
  @ViewChild('parent', {static: true}) parentEle: ElementRef;

  constructor() { }

  drawChart() {
    console.log(this.parentEle.nativeElement.offsetWidth, this.parentEle.nativeElement.offsetHeight);
    const data = this.data;
    const chart = new G2.Chart({
      container: this.myChart.nativeElement,
      // forceFit: true,
      width: this.parentEle.nativeElement.offsetWidth,
      height: this.parentEle.nativeElement.offsetHeight,
      animate: false,
      padding: [ 20, 20 ]
    });
    chart.source(data);
    chart.coord('theta', {
      innerRadius: 0.6
    });
    chart.tooltip({
      showTitle: false,
      itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
    });
    // 辅助文本
    // chart.guide().html({
    //   position: [ '50%', '50%' ],
    //   html: '<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;">主机<br><span style="color:#8c8c8c;font-size:20px">200</span>台</div>',
    //   alignX: 'middle',
    //   alignY: 'middle'
    // });

    // { item: 'Very Bad', value: 0 },
    // { item: 'Bad', value: 0 },
    // { item: 'Normal', value: 0 },
    // { item: 'Good', value: 0 },
    // { item: 'Very Good', value: 0 }


    const interval = chart.intervalStack()
      .position('value')
      .color('item', (item) => {
        switch (item) {
          case 'Very Bad': return '#c0069a';
          case 'Bad': return '#d30457';
          case 'Normal': return '#e2031e';
          case 'Good': return '#ef5318';
          case 'Very Good': return '#faad1e';
          case 'Yes': return '#c0069a';
          case 'No': return '#4d4d4d';
        }
      })
      .label('value', {
        formatter: (val, item) => {
          return item.point.item + ': ' + val + '%';
        }
      })
      .tooltip('item*value', (item, value) => {
        value = value + '%';
        return {
          name: item,
          value: value
        };
      })
      .style({
        lineWidth: 1,
        stroke: '#fff'
      });
    chart.legend(false);
    chart.render();
    // interval.setSelected(data[0]);
  }

  ngOnInit() {
    const me = this;
    setTimeout(() => {
      me.drawChart();
    }, 1);

  }

}
