import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CompanyInfo, CharityInfo, VideoInfo } from '../../my-type';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../data.service';
import { MenuItem } from 'primeng/api';
import {ConfirmationService} from 'primeng/api';

// tslint:disable: variable-name

@Component({
  selector: 'app-upload-content',
  templateUrl: './upload-content.component.html',
  styleUrls: ['./upload-content.component.css'],
  providers: [ConfirmationService]
})
export class UploadContentComponent implements OnInit {

  displayDialog = false;
  isEditMode = false;

  items: MenuItem[];
  activeItem: MenuItem;
  displayedMenu: string;

  companies: CompanyInfo[] = [];
  charities: CharityInfo[] = [];

  selectedCharity: CharityInfo = null;
  selectedThumbnailFiles: any = null;
  selectedVideoFiles: any = null;

  video_id = 0;
  total_donation = 0;
  charitiesAndAmounts: any[] = [];
  title = '';
  description = '';
  thumbnailFile: string = null;
  videoFile: string = null;
  unit_price = 1000;
  researchQuestions: string[] = [];
  start_date: Date = null;
  end_date: Date = null;
  donator_site = '';
  tag: string[] = [];

  target_ages = [
    {display: 'all', value: 'all'},
    {display: '10대', value: '10'},
    {display: '20대', value: '20'},
    {display: '30대', value: '30'},
    {display: '40대', value: '40'},
    {display: '50대', value: '50'},
    {display: '60대', value: '60'}
  ];

  target_regions = [
    {display: 'all', value: 'all'},
    {display: '서울', value: 'seoul'},
    {display: '부산', value: 'busan'},
    {display: '경기', value: 'gyeonggi'},
    {display: '기타', value: 'etc'}
  ];

  target_gender = 0;
  target_age: boolean[] = [];
  target_region: boolean[] = [];

  @Input() onDoneUpload: Function;
  @ViewChild('titleField', { static: false }) titleField: ElementRef;
  @ViewChild('questionInputField', { static: false }) questionInputField: ElementRef;
  @ViewChild('spinnerEle', { static: false }) spinnerEle: ElementRef;

  constructor(private httpClient: HttpClient, private ds: DataService, private confirmationService: ConfirmationService) { }

  open(data: VideoInfo) {
    const me = this;

    this.activeItem = this.items[0];
    this.displayedMenu = 'content';

    this.selectedThumbnailFiles = null;
    this.selectedVideoFiles = null;

    if (data) {
      this.isEditMode = true;
      this.video_id = data.id;
      this.title = data.title;
      this.description = data.description;
      this.thumbnailFile = data.thumbnail;
      this.videoFile = data.file;
      this.unit_price = data.unit_price;
      this.researchQuestions = data.survey;
      this.start_date = new Date(data.start_date);
      this.end_date = new Date(data.end_date);
      this.donator_site = data.donator_site;
      this.tag = data.tag;
      this.charitiesAndAmounts = data.donations.map(
        item => ({charity: this.charities.find(item2 => item2.id === item.charity_id), total_donation: item.total_donation}));
      this.target_gender = data.target_gender;
      this.target_age = this.target_ages.map(item => data.target_age.findIndex(item2 => item.value === item2) !== -1);
      this.target_region = this.target_regions.map(item => data.target_region.findIndex(item2 => item.value === item2) !== -1);
    } else {
      this.isEditMode = false;

      this.video_id = 0;
      this.total_donation = 0;
      this.charitiesAndAmounts = [
        {
          charity: null,
          total_donation: 0
        }
      ];
      this.title = '';
      this.description = '';
      this.thumbnailFile = null;
      this.videoFile = null;
      this.unit_price = 1000;
      this.researchQuestions = [];
      this.start_date = null;
      this.end_date = null;
      this.donator_site = '';
      this.tag = [];
      this.selectedCharity = null;
    }

    this.displayDialog = true;
    setTimeout(() => {
      me.titleField.nativeElement.focus();
    }, 100);
  }

  moveNext() {

    // this.uploadingStatus++;
  }

  addDonation() {
    this.charitiesAndAmounts.push(
      {
        charity: null,
        total_donation: 0
      }
    );
  }

  removeDonation(idx) {
    const me = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to erase this item?',
      accept: () => {
        me.charitiesAndAmounts.splice(idx, 1);
      }
    });

  }

  apply() {
    this.spinnerEle.nativeElement.style.display = 'block';

    if (!this.selectedVideoFiles && !this.selectedThumbnailFiles) {
      this.onUpload();
    } else {
      if (this.selectedThumbnailFiles) { this.thumbnailFile = null; }
      if (this.selectedVideoFiles) { this.videoFile = null; }
    }

    if (this.selectedVideoFiles) { this.onVideoFileUpload(this.selectedVideoFiles); }
    if (this.selectedThumbnailFiles) { this.onThumbnailFileUpload(this.selectedThumbnailFiles); }
  }

  delete() {
    const me = this;
    this.ds.call('video.delete',
      {
        id: this.video_id
      },
      (c, d) => {
        if (c === 200) {
          console.log('d=', d);
          me.displayDialog = false;
          me.onDoneUpload();
        }
    });
  }


  onDialogHide() {
    console.log('upload content dialog closed!');
  }

  onVideoFileUpload(file) {
    const me = this;
    console.log('file:', file);

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    this.httpClient
        .post<any>(this.ds.getFileServerUrl() + '/upload', formData)
        .subscribe(r => {
          console.log('videoFile:', r);
          me.videoFile = r.file;
          me.onUpload();
        });
  }

  onThumbnailFileUpload(file) {
    const me = this;
    console.log('file:', file);

    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    this.httpClient
        .post<any>(this.ds.getFileServerUrl() + '/upload', formData)
        .subscribe(r => {
          console.log('thumbnailFile:', r);
          me.thumbnailFile = r.file;
          me.onUpload();
        });
  }

  doEraseQuestion(idx: number) {
    const me = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to erase this item?',
      accept: () => {
        me.researchQuestions.splice(idx, 1);
      }
    });
  }

  doPushQuestion() {
    console.log(`questionInputField: ${this.questionInputField.nativeElement.value}`);
    this.researchQuestions.push(this.questionInputField.nativeElement.value);
  }

  onUpload() {
    const me = this;
    if (this.thumbnailFile != null && this.videoFile != null) {
      const payload: VideoInfo = {
        id: this.video_id,
        title: this.title,
        description: this.description,
        thumbnail: this.thumbnailFile,
        file: this.videoFile,
        survey: this.researchQuestions,
        donations: this.charitiesAndAmounts.map( item =>
          ({charity_id: item.charity.id, total_donation: parseInt(item.total_donation, 10)})),
        target_gender: this.target_gender,
        target_age: this.target_age.map( (item, idx) => (item) ? this.target_ages[idx].value : null ),
        target_region: this.target_region.map( (item, idx) => (item) ? this.target_regions[idx].value : null ),
        unit_price: this.unit_price,
        start_date: this.start_date.getTime(),
        end_date: this.end_date.getTime(),
        donator_site: this.donator_site,
        tag: this.tag
      };

      if (this.isEditMode) {
        this.ds.call('video.set', payload, (c, d: any) => {
          console.log(d);
          me.spinnerEle.nativeElement.style.display = 'none';
          if (c === 200) {
            me.displayDialog = false;
            me.onDoneUpload();
          } else {
            // TODO: 에러 메시지 표시 필요
          }
        });
      } else {
        this.ds.call('video.add', payload, (c, d: VideoInfo[]) => {
          console.log(d);
          me.spinnerEle.nativeElement.style.display = 'none';
          if (c === 200) {
            me.displayDialog = false;
            me.onDoneUpload();
          } else {
            // TODO: 에러 메시지 표시 필요
          }
        });
      }
    }
  }

  ngOnInit() {
    const me = this;

    this.items = [
      {
        label: '광고 등록',
        icon: 'fas fa-film',
        command: (e) => {this.displayedMenu = 'content'; }
      },
      {
        label: '설문 등록',
        icon: 'far fa-file-alt',
        command: (e) => {this.displayedMenu = 'research'; }
      }
    ];

    this.activeItem = this.items[0];
    this.displayedMenu = 'content';

    this.ds.call('charity.list', {}, (c, d) => {
      if (c === 200) {
        me.charities = d;
      }
    });
  }

}
