import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as Chart from 'chart.js';

declare let L;

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {

  @Input() type: string;
  @Input() data: any;
  @Input() options: any;
  @Input() canvasWidth: string;
  @Input() canvasHeight: string;
  @ViewChild('myChart', {static: true}) myChart: ElementRef;

  constructor() {
    // if (!this.canvasWidth) {
    //   this.canvasWidth = '100%';
    // }
    // if (!this.canvasHeight) {
    //   this.canvasHeight = '100%';
    // }
  }

  drawChart() {
    const ctx = this.myChart.nativeElement.getContext('2d') as HTMLCanvasElement;

    const chart = new Chart(ctx, {
      type: this.type,
      data: this.data,
      options: this.options
    });
  }

  ngOnInit() {
    this.drawChart();
  }

}
