
export let LoginType: string[] = ['BuiltIn', 'Google', 'Facebook'];

export interface DonationInfo {
    charity_id: number;
    total_donation: number;
}

export interface VideoInfo {
    id?: number;
    title: string;
    description: string;
    thumbnail: string;
    file: string;
    survey: any[];
    rating?: number;
    seen?: number;
    unit_price: number;
    start_date: number;
    end_date: number;
    donator_site: string;
    tag: string[];
    company_id?: number;
    donations: DonationInfo[];
    target_gender: number;
    target_region: string[];
    target_age: string[];
    created_date?: number;
}

export interface VideoCardInfo extends VideoInfo {
    company_name: string;
    charity_name: string;
    watched: boolean;
    number_of_views: number;
    number_of_likes: number;
    number_of_dislikes: number;
    progress_percent: number;
    total_donation: number;
    view_date: number;
}

export interface MergedVideoInfo {
    company_id: number;
    company_name: string;
    created_date: number;
    start_date: number;
    end_date: number;
    description: string;
    id: number;
    read: boolean;
    seen_rate: number;
    sum_total_donation: number;
    title: string;
    survey: any[];
    total_seen: number;
    type: string;
    thumbnail: string;
    number_of_views: number;
}


// export interface CompanyInfo {
//     name: string;
//     picture: string;
//     description: string;
//     code: string;
// }

export interface CompanyInfo {
    id: number;
    name: string;
    description: string;
    license: string;
    manager_id: string;
    owner: string;
    business_conditions: string;
    business_category: string;
    zip_code: string;
    address: string;
    phone: string;
    email: string;
    applier_name: string;
    applier_phone: string;
    pictures: string;
    video: string;
    amount_of_donation: number;
    created_date: number;
    updated_date: number;
}


export interface CharityInfo {
    id?: number;
    name: string;
    description: string;
    thumbnail: string;
    video: string;
    amount_of_received?: number;
    created_date?: number;
    updated_date?: number;
}

export interface ChartInfo {
    name: string;
    description: string;
    type: string;
    data: any;
}

export interface SessionInfo {
    id: string;
    name: string;
    picture: string;
    total_donation: number;
    geo_location: any;
    gender: number;
    age: number;
    member_type: number;
    login_type: string;
    company_id: number;
    charity_id: number;
    status: string;
    zip_code: string;
    country: string;
    active_timestamp: number;
}

export interface UserInfo {
    id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    password: string;
    salt: string;
    login_type: number;
    member_type: number;
    picture: string;
    gender: number;
    age: number;
    email: string;
    phone: string;
    address: string;
    zip_code: string;
    country: string;
    personal_id: string;
    total_donation: number;
    company_id: number;
    charity_id: number;
    created_date: number;
    updated_date: number;
    status: number;
}

export interface CompanyManagerInfo {
    id: string;
    name: string;
    email: string;
    condition: string;
    won: string;
    login_date: string;
    created_date: string;
}

export interface ViewHistory {
    id: number;
    user_id: string;
    donation_id: number;
    video_id: number;
    company_id: number;
    charity_id: number;
    unit_price: number;
    rating: number;
    feel: number;
    view_date: number;
    survey: [];
}

export interface Summary {
    total: number;
    current: number;
    seen: number;
    total_videos: number;
    current_videos: number;
}
