
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CompanyManagerInfo, UserInfo, CompanyInfo } from '../../my-type';
import { DataService } from '../../data.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
  providers: [ConfirmationService]
})
export class CompanyComponent implements OnInit {

  users: CompanyManagerInfo[] = [];
  cols: any[];
  AccountStatus: string[] = [
    '미승인', '정보입력중', '승인요청', '승인완료', '차단'
  ];

  displayDialog = false;
  selectedCompany: CompanyInfo = null;

  constructor(private ds: DataService, private confirmationService: ConfirmationService) { }

  onDialogHide() {
    this.displayDialog = false;
  }

  onRowSelect(e) {
    this.displayDialog = true;
  }

  onRowUnselect(e) {

  }

  doSubmit(id) {
    const me = this;
    this.ds.call('user.set', {
      id,
      status: 3
    }, (c, d) => {
      if (c === 200) {
        console.log(d);
        me.updateList();
      }
    });

    this.displayDialog = false;
  }

  async updateList() {
    this.users = [];
    try {
      const userInfo = await this.ds.get('user.list', {}) as UserInfo[];
      userInfo.forEach(u => {
        this.users.push({
          id: u.id,
          condition: this.AccountStatus[u.status],
          created_date: (new Date(u.created_date)).toLocaleString(),
          email: u.email,
          login_date: '',
          name: u.last_name + ' ' + u.first_name,
          won: ''
        });
      });
    } catch (e) {
      console.log('error on updsateList', e);
    }
  }

  ngOnInit() {

    this.cols = [
      { field: 'id', header: 'No' },
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'E-Mail' },
      { field: 'condition', header: 'Condition' },
      { field: 'won', header: 'W' },
      { field: 'login_date', header: 'Last Login Date' },
      { field: 'created_date', header: 'Date Requested' }
    ];

    this.updateList();

  }

}
