import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DataService } from '../../data.service';
import { SessionInfo } from '../../my-type';
import { ConfirmationService } from 'primeng/api';

declare const gapi: any;

@Component({
  selector: 'app-signup-company-member',
  templateUrl: './signup-company-member.component.html',
  styleUrls: ['./signup-company-member.component.css'],
  providers: [ConfirmationService]
})
export class SignupCompanyMemberComponent implements OnInit {

  userInfo: SessionInfo = null;
  loginType = '';
  readonly = false;
  signupStatus = 0;
  companyId = 0;

  constructor(private router: Router, private ds: DataService, private confirmationService: ConfirmationService) { }

  doLogOut() {
    const me = this;
    if (gapi.auth2) {
      const auth = gapi.auth2.getAuthInstance();
      if (auth) {
        auth.signOut().then(() => {
          console.log('User signed out.');
          me.router.navigateByUrl('/pages/splash');
        });
      } else {
        me.router.navigateByUrl('/pages/splash');
      }
    } else {
      me.router.navigateByUrl('/pages/splash');
    }
  }

  onSubmit(info) {
    const me = this;

    this.ds.call('company.add', info, (c, d) => {
      if (c === 200 && d.length !== 0 && d[0].status === 200) {
        me.confirmationService.confirm({
          message: '48시간 이내에 승인이 이뤄지고, \n이메일을 통해서 결과를 확인 하실 수 있습니다.',
          header: '접수 완료',
          icon: 'fas fa-heartbeat',
          accept: () => {
            console.log('accept');
            me.doLogOut();
          },
          rejectVisible: false
        });
      }
    });
    // this.router.navigateByUrl("/pages/main");
  }

  async updateData() {
    this.userInfo = await this.ds.get('user.info', {}) as SessionInfo;
    if (!this.userInfo) { return; }

    this.loginType = this.userInfo.login_type;
    this.companyId = this.userInfo.company_id;
    switch (this.userInfo.status) {
      case 'NeedToFill':
        this.signupStatus = 0;
        this.readonly = false;
        break;
      case 'Checking':
        this.signupStatus = 1;
        this.readonly = true;
        break;
      case 'Valid':
        this.signupStatus = 3;
        this.readonly = true;
        break;
    }
  }

  ngOnInit() {
    this.updateData();
  }

}
