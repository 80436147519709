import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CharityInfo } from '../../my-type';
import { DataService } from '../../data.service';
import { Observable} from 'rxjs';
import { CharityDetailComponent } from '../charity-detail/charity-detail.component';
import { CharityUploadContentComponent } from '../charity-upload-content/charity-upload-content.component';

@Component({
  selector: 'app-charity',
  templateUrl: './charity.component.html',
  styleUrls: ['./charity.component.css']
})
export class CharityComponent implements OnInit {

  constructor(private ds: DataService) { }

  charities: CharityInfo[] = [];
  assetDir = '/assets/';

  @ViewChild(CharityDetailComponent, { static: true }) charityDetailCmp: CharityDetailComponent;
  @ViewChild(CharityUploadContentComponent, { static: true }) uploadContentCmp: CharityUploadContentComponent;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();


  onDoneUpload = function() {
    console.log('doneUpload');
    this.updateList();
  }.bind(this);

  openShowContentDialog(e, charity) {
    console.log(`charity:${JSON.stringify(charity)}`);
    e.stopPropagation();
    // this.charityDetailCmp.show(charity);
  }

  openDialogForNewContent(e) {
    e.stopPropagation();
    this.uploadContentCmp.open(null);
  }

  openDialogForModify(e, data) {
    e.stopPropagation();
    this.uploadContentCmp.open(data);
  }

  getDescription(video: CharityInfo) {
    if (video.description.length > 90) {
      return video.description.substring(0, 90) + '...';
    } else {
      return video.description;
    }
  }

  updateList() {
    const me = this;
    this.ds.call('charity.list', {}, (c, d) => {
      console.log(`d=`, d);

      me.charities = d;
    });
  }

  ngOnInit() {
    this.assetDir = this.ds.getAssetDir();
    this.updateList();
  }

}
