import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  selectedMenu = 'info';

  constructor() { }

  changeTab(menu) {
    this.selectedMenu = menu;
  }

  ngOnInit() {
  }

}
