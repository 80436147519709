import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DataService } from '../../data.service';
import { CompanyInfo, UserInfo, LoginType } from '../../my-type';

@Component({
  selector: 'app-company-member-view',
  templateUrl: './company-member-view.component.html',
  styleUrls: ['./company-member-view.component.css']
})
export class CompanyMemberViewComponent implements OnInit {

  @Input() userId: string = null;
  @Input() readonly: Boolean = false;

  items: MenuItem[];
  activeIndex = 1;

  login_type = '';
  name = '';
  license_1 = '';
  license_2 = '';
  license_3 = '';
  owner = '';
  business_conditions = '';
  business_category = '';
  zip_code = '';
  address_1 = '';
  address_2 = '';
  phone_1 = '';
  phone_2 = '';
  phone_3 = '';
  email = '';
  applier_name = '';
  applier_phone_1 = '';
  applier_phone_2 = '';
  applier_phone_3 = '';

  signupStatus = 0;

  constructor(private ds: DataService) { }

  setCompanyField(company_id: number) {
    const me = this;
    this.ds.call('company.get', { 'id': company_id }, function(c, d: CompanyInfo) {

      if (d.license.length > 0) {
        const spLicense = d.license.split('-');
        me.license_1 = spLicense[0];
        me.license_2 = spLicense[1];
        me.license_3 = spLicense[2];
      }

      me.address_1 = '';
      me.address_2 = d.address;
      if (d.phone.length > 0) {
        const spPhone = d.phone.split('-');
        me.phone_1 = spPhone[0];
        me.phone_2 = spPhone[1];
        me.phone_3 = spPhone[2];
      }

      if (d.applier_phone.length > 0) {
        const spApplierPhone = d.applier_phone.split('-');
        me.applier_phone_1 = spApplierPhone[0];
        me.applier_phone_2 = spApplierPhone[1];
        me.applier_phone_3 = spApplierPhone[2];
      }

      me.name = d.name;
      me.owner = d.owner;
      me.business_conditions = d.business_conditions;
      me.business_category = d.business_category;
      me.zip_code = d.zip_code;
      me.email = d.email;
      me.applier_name = d.applier_name;

    });
  }

  async ngOnInit() {
    const me = this;
    const currentUser = await this.ds.get('user.info', {}) as UserInfo;
    if (currentUser.id === this.userId) {
      this.setCompanyField(currentUser.company_id);
      this.login_type = LoginType[currentUser.login_type];
    } else {
      this.ds.call('user.get', {user_id: this.userId }, (c, d: UserInfo) => {
        if (c === 200) {
          me.setCompanyField(d.company_id);
          me.login_type = LoginType[d.login_type];
        }
      });
    }
  }

}
